import styled from 'styled-components/macro'
import { Icon } from '@scuf/common'
import { DataTable } from '@scuf/datatable'

export const StyledSubTitle = styled.div`
    font-weight: 800;
    font-size: 1rem;
    color: #303030;
    margin-bottom: 1rem;
`
export const StyledConfirmationContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const StyledReviewText = styled.div`
    font-size: .875rem;
    font-weight: 500;
    color: #707070;
`
export const StyledSoftwareContainer = styled.div`
    margin: .5rem 0 1rem 0;
    tbody {
        max-height: calc(10vh) !important;
    }
    th, td {
        padding: .25rem 1.5rem .25rem 1rem !important;
    }
`
export const StyledDevicesContainer = styled.div`
    margin: 1rem 0 1rem 0;
`
export const StyledAssignedContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;    
`
export const StyledAssignedIcon = styled(Icon)`
    color: #303030;
    font-weight: 600;
`
export const StyledAssignedText = styled.div`
    font-size: 14px;
    font-weight: 700;
    margin-left: .5rem;
`
export const StyledLabel = styled.span`
    font-size: .875rem;
    font-weight: 700;
    color: #404040;    
`
export const StyledBundleName = styled.span`
    font-size: .875rem;
    font-weight: 500;
    color: #404040;
`
export const StyledDataTable = styled(DataTable)`
    margin-top:.5rem;
    .p-datatable-thead p-datatable-row {
        height: 24px !important;
    }
    .p-datatable-header {
        height: auto !important;
        max-width: min-content;
        max-inline-size: min-content;
    }
`
export const StyledScheduleContainer = styled.div`
    margin: 2rem 0 1rem 0;
`

export const StyledScheduleSubContainer = styled.div`
    display: flex;
`
export const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
`
export const StyledValue = styled.div`
     font-size: .875rem;
     font-weight: 600;
     color: #303030;
`
