import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'

import {
  NotificationsTypes,
  NotificationsActions
} from './actions'

import { NotificationsApi } from '../../../Services'
import { SessionExpiryActions } from '../../SessionExpiry/store'

export function * getNotifications (api) {
  try {
    const response = yield call(api.getNotifications)
    if (response.ok) {
      yield put(NotificationsActions.updateNotifications(formatNotificationsData(response.data.notifications)))
    } else if (response.status === 401) {
      yield all([
        put(SessionExpiryActions.sessionExpiry())
      ])
      yield all([
        put(NotificationsActions.updateNotificationFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(NotificationsActions.updateNotificationFailure())
    ])
  }
}

export function * statusUpdateNotifications (api, { notificationIds }) {
  try {
    const response = yield call(api.statusUpdateNotifications, notificationIds)
    if (response.ok) {
      if (response.data.statusCode === '200') {
        yield all([
          put(NotificationsActions.statusUpdateNotificationsComplete())
        ])
      }
    } else if (response.status === 401) {
      yield all([
        put(SessionExpiryActions.sessionExpiry())
      ])
    } else {
      yield all([
        put(NotificationsActions.statusUpdateNotificationsComplete())
      ])
    }
  } catch (_) {
    yield all([
      put(NotificationsActions.statusUpdateNotificationsComplete())
    ])
  }
}

function formatNotificationsData (data) {
  const response = []
  let count = 0

  for (let i = 0; i < data.length; i++) {
    response[count++] = {
      id: data[i].id,
      title: data[i].title,
      message: data[i].message,
      type: data[i].severity.toLowerCase(),
      time: data[i].createdOn,
      status: data[i].status,
      siteId: data[i].siteId,
      siteName: data[i].siteName
    }
  }
  return response
}

export function * notificationsSagas () {
  yield all([
    takeLatest(NotificationsTypes.GET_NOTIFICATIONS, getNotifications, NotificationsApi),
    takeLatest(NotificationsTypes.STATUS_UPDATE_NOTIFICATIONS, statusUpdateNotifications, NotificationsApi)
  ])
}
