import { combineReducers } from '@reduxjs/toolkit'

import { licensesReducer } from '../Features/Licenses/store'
import { licenseInstallerReducer } from '../Features/LicenseInstaller/store'
import { licenseImportReducer } from '../Features/LicenseImport/store'
import { sessionExpiryReducer } from '../Features/SessionExpiry/store'
import { schedulesReducer } from '../Features/Schedules/store'
import { historysReducer } from '../Features/Historys/store'
import { licenseImportOnPremReducer } from 'Features/LicenseImportOnPrem'
import { devicesReducer } from '../Features/Devices/store'
import { othersReducer } from '../Features/Others/store'
import { notificationsReducer } from '../Features/NotificationBell/store'
import { opintelLicensesReducer } from 'Features/OpintelLicenses/store'

export const rootReducer = combineReducers({
  licenses: licensesReducer,
  licenseInstaller: licenseInstallerReducer,
  schedules: schedulesReducer,
  historys: historysReducer,
  devices: devicesReducer,
  others: othersReducer,
  licenseImport: licenseImportReducer,
  licenseImportOnPrem: licenseImportOnPremReducer,
  sessionExpiry: sessionExpiryReducer,
  notifications: notificationsReducer,
  opintelLicenses: opintelLicensesReducer
})

export type AppState = ReturnType<typeof rootReducer>
