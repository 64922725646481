import { createActions } from 'reduxsauce'

export enum TypesNames {
  LOADING = 'LOADING',
  IMPORT_LICENSE = 'IMPORT_LICENSE',
  IMPORT_LICENSE_COMPLETE = 'IMPORT_LICENSE_COMPLETE',
  RESET = 'RESET'
}

// type ActionTypes = keyof typeof TypesNames

type TActionTypes = {
  [TypesNames.LOADING]: string,
  [TypesNames.IMPORT_LICENSE]: string
  [TypesNames.IMPORT_LICENSE_COMPLETE]: string
  [TypesNames.RESET]: string

}

export type TActionParamTypes = {
  activationId: string,
  hostId: string,
  devicesCount: number
}

const { Types, Creators } = createActions<TActionTypes>({
  loading: null,
  importLicense: ['activationId', 'hostId', 'downloadType', 'devicesCount'],
  importLicenseComplete: null,
  reset: null
}, {
  prefix: '@LICENSEIMPORT-ONPREM'
})

export const LicenseImportOnPremTypes = Types
export const LicenseImportOnPremActions = Creators
