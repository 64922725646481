import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { LicenseImportActions } from '../../store'
import LicenseImportModal from './licenseImportModal'
import mapStateToProps from './licenseImportModal.selector'
import { LicensesActions } from '../../../Licenses/store'
import { OpintelLicensesActions } from '../../../OpintelLicenses/store'
import config from 'Config/AppConfig'

const mapDispatchToProps = dispatch => ({
  getSites: (params) => {
    if (!config.nonSinaps)
    { return dispatch(LicenseImportActions.getSites()) }
  },
  importLicense: (activationId, licenseNo, siteId, siteName) => dispatch(LicenseImportActions.importLicense(activationId, licenseNo, siteId, siteName)),
  reset: () => dispatch(LicenseImportActions.reset()),
  refreshLicenses: () => dispatch(LicensesActions.getLicenses()),
  refreshOpintelLicenses: () => dispatch(OpintelLicensesActions.getOpintelLicenses())
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(LicenseImportModal)
