import config from 'Config/AppConfig'
export class OthersService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

  getOthersList = () => this.api.get(this.licenseController + '/licensebundles?filter={"CompatibleDeviceType":"ONPREMISEOI"}')
  othersBundleExport = (params) => this.api.post(this.licenseController + '/bundle:export', params)
}
