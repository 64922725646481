import React, { useEffect } from 'react'
import moment from 'moment'
import {
  DevicesContainer,
  TableContainer,
  StyledDataTable
} from '../../devices.styles'

function Render ({ value }) {
  return <p>{value}</p>
}

function _dateFormat ({ value }) {
  const finalValue = value ? moment(value).locale(window.navigator.language).format('MM-DD-YYYY HH:mm:ss') : 'Not Available'
  return <p>{finalValue}</p>
}

const Devices = ({
  loading,
  devices,
  getDevicesList
}) => {
  useEffect(() => {
    getDevicesList()
  }, [getDevicesList])

  const { Item } = StyledDataTable.ActionBar
  const columns = [
    { field: 'type', header: 'Type', initialWidth: '15%', render: Render },
    { field: 'alias', header: 'Name', initialWidth: '20%', render: Render },
    { field: 'model', header: 'Model', initialWidth: '15%', render: Render },
    { field: 'serialNumber', header: 'Serial Number', initialWidth: '25%', render: Render },
    { field: 'addedOn', header: 'Added On', initialWidth: '25%', render: _dateFormat }
  ]

  return (
    <DevicesContainer>
      <TableContainer>
        <StyledDataTable
          loading={loading}
          data={devices}
          search={true}
          searchPlaceholder='Search for software'
          // selection={selectedRows}
          // selectionMode='multiple'
          // onSelectionChange={(rows) => setSelectedRows(rows)}
          scrollHeight='58vh'
          scrollable={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            <Item
              icon='refresh'
              iconSize='small'
              content='Retry Failed'
              onClick={() => {}}
            />
          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
    </DevicesContainer>
  )
}

export default Devices
