import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'

import {
  SchedulesTypes,
  SchedulesActions
} from './actions'

import { SchedulesApi } from '../../../Services'
import {SessionExpiryActions} from '../../SessionExpiry/store'
export function* getSchedules(api) {
  try {
    const response = yield call(api.getSchedules)
    if (response.ok) {
      yield put(SchedulesActions.updateSchedules(formatSchedulesData(response.data)))
    } else {
      if(response.status == 401)
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      console.log("Error getting Schedules..")
      yield put(SchedulesActions.updateSchedulesFailure(response.data))
    }
  } catch (_) {
    console.log("Error getting Schedules")
    yield all([
      put(SchedulesActions.updateSchedulesFailure())
    ])
  }
}

export function* handleDelete(api, selectedSoftwares) {
  yield all([
    put(SchedulesActions.requestComplete())
  ])
}

function formatSchedulesData(data) {
  let response = []
  var count = 0
  for (var i = 0; i < data.length; i++) {
    if (data[i].jobStatus == "SCHEDULED") {
      for (var j = 0; j < data[i].softwareDetail.length; j++) {
        response[count++] = {
          'id': data[i].masterGuid + '.' + data[i].softwareDetail[j].deviceSerialNumber,
          'displayName': data[i].softwareDetail[j].displayName.replace("License Configuration: ", ""),
          'deviceSerialNumber': data[i].softwareDetail[j].deviceSerialNumber,
          'status': data[i].softwareDetail[j].status,
          'scheduledVersion': data[i].softwareDetail[j].scheduledVersion,
          'scheduledDate': data[i].softwareDetail[j].scheduledDate,
          'scheduledTimeZone': data[i].softwareDetail[j].scheduledTimeZone,
          'version': data[i].softwareDetail[j].scheduledVersion,
        }
      }
    }
  }
  return response
}

export function* schedulesSagas() {
  yield all([
    takeLatest(SchedulesTypes.GET_SCHEDULES, getSchedules, SchedulesApi),
    takeLatest(SchedulesTypes.HANDLE_DELETE, handleDelete, SchedulesApi)
  ])
}
