import { createStructuredSelector, createSelector } from 'reselect'

const selectedSoftwares = ({ licenseInstaller }) => licenseInstaller.getIn(['selectedSoftwares'], [])
const devices = ({ licenseInstaller }) => licenseInstaller.getIn(['devicesList'], [])
const loadingDevices = ({ licenseInstaller }) => licenseInstaller.getIn(['loading'], false)
const selectedDevicesList = ({ licenseInstaller }) => licenseInstaller.getIn(['selectedDevicesList'], [])
const bundleName = ({ licenseInstaller }) => licenseInstaller.getIn(['bundleName'], '')
const bundleDevices = ({ licenseInstaller }) => licenseInstaller.getIn(['bundleDevices'], [])
const sitesList = ({ licenseInstaller }) => licenseInstaller.getIn(['sitesList'], [])
const deviceFilterType = ({ licenseInstaller }) => licenseInstaller.getIn(['deviceFilterType'], '')
const devicesCount = ({ licenseInstaller }) => licenseInstaller.getIn(['totalDevicesCount'], [])
const createBundleFilter = ({ licenseInstaller }) => licenseInstaller.getIn(['createBundleFilter'], {})
const siteId = createSelector(
  [selectedSoftwares],
  softwares => softwares.length > 0 ? softwares[0].siteId : null
)

export default createStructuredSelector({
  devices,
  bundleDevices,
  loadingDevices,
  selectedBundleName: bundleName,
  selectedDevicesList,
  siteId,
  sitesList,
  deviceFilterType,
  devicesCount,
  createBundleFilter
})
