import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loading: null,
  getLicenses: null,
  updateLicenses: ['licenses'],
  updateLicensesFailure: null,
  getLicenseBundles: null,
  updateLicenseBundles: ['licenseBundles'],
  updateLicenseBundleFailure: null,
  updateLicenseBundleExportFailure: null,
  reset: null,
  licenseBundleExport: ['licenseBundle'],
  updateExportInProgress: null,
  getUserDetails: null,
  updateLoginEmailAddress: ['loginEmailAddress'],
  getDevices: ['siteId'],
  updateUserAccessibleDeviceList: ['devices']
}, {
  prefix: '@LICENSES/'
})

export const LicensesTypes = Types
export const LicensesActions = Creators
