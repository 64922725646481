import { createStructuredSelector } from 'reselect'

const othersList = ({ others }) => others.getIn(['othersList'], [])
const loading = ({ others }) => others.getIn(['loading'], false)
const exportInProgress = ({ others }) => others.getIn(['exportInProgress'], false)

export default createStructuredSelector({
  loading,
  othersList,
  exportInProgress
})
