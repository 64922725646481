import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Button, Input } from '@scuf/common'
import { Title } from 'Themes/ScufStyledComponents'
import Loader from 'Components/Loader'
import config from 'Config/AppConfig'
import {
  ActionBarSection,
  ActionContainer,
  StyledIcon,
  ActionText,
  StyledModalContent,
  StyledSelect,
  StyledInput
} from '../../licenseImport.styles'

const LicenseImportModal = ({
  loading,
  sites,
  getSites,
  importLicense,
  reset,
  refreshLicenses,
  organization,
  refreshOpintelLicenses,
  licenseImportResult,
  handleTabSwitch,
  isOpintel
}) => {
  useEffect(() => {
    return () => reset()
  }, [getSites, reset])

  const [activationId, updateActivationId] = useState('')
  const [licenseNo, updateLicenseNo] = useState(0)
  const [siteId, updateSiteId] = useState('')
  const [siteName, updateSiteName] = useState('')
  const [importLicenseModalOpen, setimportLicenseModalOpen] = useState(false)
  const [statusCode, setStatusCode] = useState('')
  const [opintelStatus, setOpintelStatus] = useState(isOpintel)

  useEffect(() => {
    setOpintelStatus(isOpintel)
  },[isOpintel])
  
  const handleActivationIdChange = (event) => {
    const activationId = event.toString()
    const validActivationId = activationId.replace(/\s/g, '')
    updateActivationId(validActivationId)
  }

  const handlelicenseNoChange = useCallback(
    (val) => {
      if (val === '') {
        updateLicenseNo(0)
        return
      }
      const licenseNum = parseInt(val)
      if (licenseNum > 0 && licenseNum <= 99999) {
        updateLicenseNo(val)
      }
    }, [updateLicenseNo])

  useEffect(() => {
    if (licenseImportResult != null) {
      setStatusCode(licenseImportResult.statusCode)
    }
  }, [licenseImportResult])

  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault()
    }
  }

  function handleSubmit () {
    importLicense(activationId, licenseNo, organization.orgId, organization.orgName)
  }

  function handleCloseModal () {
    reset()
    updateActivationId('')
    updateLicenseNo(0)
    setimportLicenseModalOpen(false)
    refreshLicenses()
    refreshOpintelLicenses()
    handleTabSwitch(statusCode)
    setStatusCode('')
  }

  function handleOpenModal () {
    getSites()
    setimportLicenseModalOpen(true)
  }

  return (
    <ActionBarSection style={{ justifyContent: 'flex-end' }}>
      <ImportLicensesModal
        handleActivationIdChange={handleActivationIdChange}
        handlelicenseNoChange={handlelicenseNoChange}
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        handleKeyDown={handleKeyDown}
        importLicenseModalOpen={importLicenseModalOpen}
        sites={sites}
        loading={loading}
        activationId={activationId}
        licenseNo={licenseNo}
        siteId={siteId}
        isOpintel={opintelStatus}
      />
      <ActionContainer
        onClick={() => handleOpenModal()}
      >
        <StyledIcon
          name='cloud-download'
          root='common'
          size='xlarge'
        />
        <ActionText>
          Import Licenses
        </ActionText>
      </ActionContainer>
    </ActionBarSection>
  )
}

function ImportLicensesModal ({
  handleActivationIdChange,
  handlelicenseNoChange,
  handleSubmit,
  handleCloseModal,
  handleKeyDown,
  importLicenseModalOpen,
  sites,
  loading,
  activationId,
  licenseNo,
  siteId,
  isOpintel
}) {
  return (
    <Modal size='mini' closeIcon={true} onClose={handleCloseModal} open={importLicenseModalOpen}>
      <Modal.Header>
        {loading ? <Loader loading={loading} style={{ position: 'relative' }} /> : <div />}
        <Title style={{ textTransform: 'none' }}>Import Licenses</Title>
      </Modal.Header>
      <StyledModalContent>
        <p>Enter the activation ID and the number of licenses</p>
        <Input
          fluid={true}
          placeholder='Activation ID'
          label='Activation ID'
          value={activationId}
          onChange={handleActivationIdChange}
          indicator='required'
          maxLength={255}
        />

        <StyledInput
          indicator='required'
          fluid={true}
          placeholder='Licenses'
          type='number'
          label='Number of licenses'
          max='99999'
          onKeyDown={handleKeyDown}
          value={licenseNo > 0 && licenseNo <= 99999 ? licenseNo : ''}
          onChange={(val) => handlelicenseNoChange(val)}
          helperText='Count requested should be less than or equal to the purchased count'
        />

      </StyledModalContent>
      <Modal.Footer>
        <Button fluid={true} type='secondary' size='small' content='Cancel' onClick={handleCloseModal} />
        <Button
          fluid={true} type='primary' size='small' content='Import'
          disabled={!(activationId.length > 0 && licenseNo > 0 && (siteId.length === 0 || config.nonSinaps || isOpintel) && loading === false)}
          onClick={() => handleSubmit()}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(LicenseImportModal)
