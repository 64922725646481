import React, { useEffect } from 'react'
import moment from 'moment'
import {
  HistorysContainer,
  TableContainer,
  StyledDataTable
} from './historys.styles'

function Render ({ value }) {
  return <p>{value}</p>
}

function _dateFormat ({ value }) {
  const finalValue = value ? moment(value).locale(window.navigator.language).format('MM-DD-YYYY HH:mm:ss') : 'Not Available'
  return <p>{finalValue}</p>
}

const Historys = ({
  loading,
  historys,
  getHistorys
}) => {
  useEffect(() => {
    getHistorys()
  }, [getHistorys])

  const { Item } = StyledDataTable.ActionBar
  const columns = [
    { field: 'displayName', header: 'Bundle Name', initialWidth: '20%', render: Render },
    { field: 'deviceSerialNumber', header: 'Serial Number', initialWidth: '15%', render: Render },
    { field: 'status', header: 'status', initialWidth: '15%', render: Render },
    { field: 'scheduledDate', header: 'Scheduled Date', initialWidth: '20%', render: _dateFormat },
    { field: 'scheduledTimeZone', header: 'Time Zone', initialWidth: '15%', render: Render }
  ]

  return (
    <HistorysContainer>
      <TableContainer>
        <StyledDataTable
          loading={loading}
          data={historys}
          search={true}
          searchPlaceholder='Search for Historys Items'
          scrollHeight='58vh'
          scrollable={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            <Item
              icon='refresh'
              iconSize='small'
              content='Retry Failed'
              onClick={() => {}}
            />
          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
    </HistorysContainer>
  )
}

export default Historys
