import styled from 'styled-components/macro'
import { Icon } from '@scuf/common'

export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'} ;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
`

export const ActionText = styled.div`
  font-size: 14px;
  color: #303030;
  padding-left: 0.25rem;
`

export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1.2rem !important;
  margin-top: 0.15rem;
  padding-left: 1.5rem;
`
