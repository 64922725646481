import config from 'Config/AppConfig'

export class NotificationsService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

  getNotifications = () => this.api.get(this.licenseController + '/notifications?sort=[{"property":"createdOn","ascending":"false"}]')
  statusUpdateNotifications = (params) => this.api.put(this.licenseController + '/notifications', params)
}
