import React from 'react'

import { Table } from '@scuf/common'

import {
  StyledTableContainer
} from './software.styles'

const Software = ({ selectedSoftwares }) => {
  return (
    <StyledTableContainer>
      <Table>
        <Table.Header>
          <Table.HeaderCell content='Software Selected' />
          <Table.HeaderCell content='Activation ID' />
          <Table.HeaderCell content='Licenses Available' />
          <Table.HeaderCell content='Maintenance Expiry' />
          <Table.HeaderCell content='Expiration Date' />
        </Table.Header>
        <Table.Body>
          {
            selectedSoftwares.map(row =>
              <Table.Row key={row.activationId}>
                <Table.Cell>{row.featureName}</Table.Cell>
                <Table.Cell>{row.activationId}</Table.Cell>
                <Table.Cell>{row.availableCount}</Table.Cell>
                <Table.Cell>{row.expiresOn}</Table.Cell>
                <Table.Cell>{row.expirationDate}</Table.Cell>
              </Table.Row>
            )
          }
        </Table.Body>
      </Table>
    </StyledTableContainer>
  )
}

export default Software
