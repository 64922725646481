import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  SchedulesContainer,
  TableContainer,
  StyledDataTable
} from './schedules.styles'


function Render ({ value }) {
  return <p>{value}</p>
}
function _dateFormat({value}) {
  const finalValue = value ? moment(value).locale(window.navigator.language).format('MM-DD-YYYY HH:mm:ss') : 'Not Available'
  return <p>{finalValue}</p>
}



const Schedules = ({
  loading,
  schedules,
  getSchedules,
  deleteSelectedCommands
}) => {
  const [selectedRows, setSelectedRows] = useState([])
  

  useEffect(() => {
    getSchedules()
  }, [getSchedules])

  function handleDelete () {
    deleteSelectedCommands(selectedRows)
  }

  const { Item } = StyledDataTable.ActionBar
  const columns = [
    { field: 'displayName', header: 'Bundle Name', initialWidth: '20%', render: Render },
    { field: 'deviceSerialNumber', header: 'Serial Number', initialWidth: '15%', render: Render },
    { field: 'status', header: 'status', initialWidth: '15%', render: Render },
    { field: 'scheduledDate', header: 'Scheduled Date', initialWidth: '20%', render: _dateFormat },
    { field: 'scheduledTimeZone', header: 'Time Zone', initialWidth: '15%', render: Render },
  ]

  return (
    <SchedulesContainer>
      <TableContainer>
        <StyledDataTable
          loading={loading}
          data={schedules}
          search={true}
          searchPlaceholder='Search for Schedules Items'
          //selection={selectedRows}
          //selectionMode='multiple'
          //onSelectionChange={(rows) => setSelectedRows(rows)}
          scrollHeight='58vh'
          scrollable={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            <Item
              icon='delete'
              iconSize='small'
              content='Delete'
              onClick={handleDelete}
            />
          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
    </SchedulesContainer>
  )
  function resetSoftwareData () {
    setSelectedRows([])
  }
}

export default Schedules
