import React from 'react'
import { Provider } from 'react-redux'

import { store } from './Store'
import AppFrame from './Components/AppFrame'

import { ConnectedThemeProvider } from './Themes/connected-theme-provider'
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { compose } from 'ramda';
import '@scuf/common/honeywell-compact-combined/theme.css'
import '@scuf/datatable/honeywell-compact-combined/theme.css'
import './App.css'
import config from 'Config/AppConfig'
import { defaultFlags } from 'Utils/launch-darkly-flags'

function App () {
  return (
    <Provider store={store}>
      <ConnectedThemeProvider>
        <AppFrame />
      </ConnectedThemeProvider>
    </Provider>
  )
}

export default compose(withLDProvider({
  clientSideID: config.launchDarkly.clientId,
  context: {
    kind: 'user',
    key: config.launchDarkly.clientType,
  },
  options: {
    bootstrap: defaultFlags,
  },
}),)(App)
