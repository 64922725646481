import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loading: null,
  getHistorys: null,
  updateHistorys: ['historys'],
  updateHistorysFailure: null
}, {
  prefix: '@HISTORYS/'
})

export const HistorysTypes = Types
export const HistorysActions = Creators
