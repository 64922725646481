import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import {
  LicensesTypes,
  LicensesActions
} from './actions'

import { SessionExpiryActions } from '../../SessionExpiry/store'

import { LicensesApi } from 'Services'

import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'

export function * getLicenses (api) {
  try {
    const response = yield call(api.getLicenses)
    if (response.ok) {
      yield put(LicensesActions.updateLicenses(response.data.licenses))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(LicensesActions.updateLicensesFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(LicensesActions.updateLicensesFailure())
    ])
  }
}

export function * getLicenseBundles (api) {
  try {
    const response = yield call(api.getLicenseBundles)
    if (response.ok) {
      yield put(LicensesActions.updateLicenseBundles(response.data.licenseBundles))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(LicensesActions.updateLicenseBundleFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(LicensesActions.updateLicenseBundleFailure())
    ])
  }
}

export function * licenseBundleExport (api, { licenseBundle }) {
  try {
    const response = yield call(api.licenseBundleExport, licenseBundle)
    if (response.ok) {
      const { statusCode } = response.data.licenseResponseBundle
      yield put(LicensesActions.updateExportInProgress())
      if (response.data.licenseResponseBundle.statusCode === '200') {
        const fileContent = atob(response.data.downloadXml.xmlContent)
        const blob = new Blob([fileContent], { type: 'text/xml' })
        if (blob.size > 0) {
          saveAs(blob, response.data.downloadXml.xmlName, true)
          toast(<SuccessToast message='License Bundle Exported Successfully.' />)
        }
      } else if (statusCode === '107') {
        toast(<ErrorToast message='License Bundle Not Found.' />)
      } else {
        toast(<ErrorToast message='Failed to Export License Bundle' />)
      }
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield put(LicensesActions.updateExportInProgress())
      toast(<ErrorToast message='Failed to export License Bundle' />)
    }
  } catch (_) {
    yield put(LicensesActions.updateExportInProgress())
    toast(<ErrorToast message='Failed to export License Bundle' />)
  }
}

export function * getUserDetails (api) {
  try {
    const response = yield call(api.getUserDetails)
    if (response.ok) {
      yield put(LicensesActions.updateLoginEmailAddress(response.data.personIdentifier.loginEmailAddress))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }      
    }
  } catch (_) {
  }
}

export function * getDevices (api, { siteId }) {
  try {
    const response = yield call(api.getDevices, { siteId })
    if (response.ok && response.data) {            
      yield put(LicensesActions.updateUserAccessibleDeviceList(response.data.data))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      toast(<ErrorToast message='Failed to fetch device list of the selected bundle.' />)
    }
  } catch (_) {
    toast(<ErrorToast message='Failed to fetch device list of the selected bundle.' />)
  }
}

export function * licensesSagas () {
  yield all([
    takeLatest(LicensesTypes.GET_LICENSES, getLicenses, LicensesApi),
    takeLatest(LicensesTypes.GET_LICENSE_BUNDLES, getLicenseBundles, LicensesApi),
    takeLatest(LicensesTypes.LICENSE_BUNDLE_EXPORT, licenseBundleExport, LicensesApi),
    takeLatest(LicensesTypes.GET_USER_DETAILS, getUserDetails, LicensesApi),
    takeLatest(LicensesTypes.GET_DEVICES, getDevices, LicensesApi)
  ])
}
