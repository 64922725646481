import React, { useState, useEffect, useCallback } from 'react'
import { Title } from '../../../../Themes/ScufStyledComponents'
import { Modal, Button, Input, Icon } from '@scuf/common'
import Loader from '../../../../Components/Loader'
import { v4 as uuidv4 } from 'uuid'
import { saveAs } from 'file-saver'
import { CSVLink } from 'react-csv'
import config from 'Config/AppConfig'
import {
  ActionBarSection,
  ActionContainer,
  StyledIcon,
  ActionText,
  StyledModalContent,
  StyledDropdown,
  FlexContainer,
  FlexGrow3,
  StyledTitle,
  StyledModal,
  DevicesContainer,
  TableContainer,
  StyledDataTable,
  StyledButton,
  StyledDiv,
  StyledFileDrop,
  ExportButton
} from '../../devices.styles'

const AddDevices = ({
  loading,
  deviceTypes,
  getDeviceTypes,
  addDevices,
  deviceDiscovery,
  discoveredDevices,
  deviceDiscoveryResponse,
  discoveredDeviceList,
  reset,
  refreshDevices,
  devicesList
}) => {
  const [deviceName, setDeviceName] = useState('')
  const [deviceModel, setDeviceModel] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const [deviceType, updateDeviceType] = useState('')
  const [addDevicesModalOpen, setaddDevicesModalOpen] = useState(false)
  const [deviceDiscoveryModalOpen, setDeviceDiscoveryModalOpen] = useState(false)
  const [qrImage, setQrImage] = useState('')
  const [stopDiscoveryDevice, setStopDiscoveryDevice] = useState(false)
  let currentTime = 0
  const [sessionId, setSessionId] = useState('')
  const [devices, updateDevices] = useState([])
  const stopThreshold = 45 * 60 * 1000
  const duration = 1 * 60 * 1000
  let intervalId

  useEffect(() => {
    if (sessionId) {
      intervalId = setInterval(() => {
        discoveredDevices(sessionId)
        currentTime += duration
        if (currentTime >= stopThreshold) {
          handleToStopDeviceDiscovery()
        }
      }, duration)
    }
    return () => clearInterval(intervalId)
  }, [sessionId, discoveredDevices])

  useEffect(() => {
    if (Object.entries(discoveredDeviceList).length > 0) {
      const arrObj = []
      updateDevices((devices) => {
        discoveredDeviceList.forEach((item) => {
          const index = devices.findIndex((ele) => ele.alias === item.alias)
          if (index === -1) {
            arrObj.push(item)
          }
        })
        return [...devices, ...arrObj]
      })
    }
  }, [discoveredDeviceList])
  const { Item } = StyledDataTable.ActionBar
  const handleDeviceNameChange = useCallback(
    (val) => {
      const inputDeviceName = val.toString()
      const validName = inputDeviceName.replace(/[^0-9a-zA-Z +-.@_]+/g, '')
      setDeviceName(validName)
    }, [setDeviceName])

  const handleDeviceModelChange = useCallback(
    (val) => {
      const inputDeviceModel = val.toString()
      const validModel = inputDeviceModel.replace(/(^[0-9a-zA-Z]+\w{6})+/g, '')
      setDeviceModel(validModel)
    }, [setDeviceModel])

  const handleSerialNumberChange = useCallback(
    (val) => {
      const inputSerialNumber = val.toString()
      const validSerialNumber = inputSerialNumber.replace(/[^0-9a-zA-Z]+/g, '')
      setSerialNumber(validSerialNumber)
    }, [setSerialNumber])

  const handleAddDevice = () => {
    const deviceData = {
      type: deviceType || 'Mobile Computer',
      alias: deviceName || deviceModel + serialNumber,
      model: deviceModel,
      serialNumber,
      uniqueId: uuidv4()
    }
    updateDevices([...devices, deviceData])
    setDeviceModel('')
    setDeviceName('')
    setSerialNumber('')
  }

  function handleDeviceTypeChange (event) {
    updateDeviceType(deviceTypes[event].data.deviceType)
  }

  function handleDeviceListSubmit () {
    const devList = JSON.parse(devicesList)
    const devicesData = [...devList, ...devices]
    const devArr = []
    devicesData.forEach(element => {
      const contains = devArr.some(elem => {
        return JSON.stringify(element.alias) === JSON.stringify(elem.alias)
      })
      if (!contains) {
        devArr.push(element)
      }
    })
    addDevices(devArr)
    handleCloseModal()
  }

  const onDeviceRowDeleteClick = (rowData) => {
    const devicesarr = [...devices]
    const index = devicesarr.findIndex(uid => uid.uniqueId === rowData.uniqueId)
    if (index !== -1) {
      devicesarr.splice(index, 1)
      updateDevices(devicesarr)
    }
  }

  function handleCloseModal () {
    reset()
    setaddDevicesModalOpen(false)
    setStopDiscoveryDevice(false)
    setSessionId('')
    currentTime = 0
    refreshDevices()
    updateDevices([])
  }

  function handleDeviceOpenModal () {
    getDeviceTypes()
    setaddDevicesModalOpen(true)
  }

  function handleDeviceDiscovery () {
    setDeviceDiscoveryModalOpen(true)
    deviceDiscovery()
  }

  function handleDiscoveryCloseModal () {
    setDeviceDiscoveryModalOpen(false)
    setStopDiscoveryDevice(true)
    if (deviceDiscoveryResponse.sessionId) {
      const { sessionId } = deviceDiscoveryResponse
      setSessionId(sessionId)
    }
  }

  function handleToStopDeviceDiscovery () {
    setStopDiscoveryDevice(false)
    currentTime = 0
    setSessionId('')
  }

  function handleDeviceDiscoveryModalOpen () {
    if (deviceDiscoveryResponse.imageUrl) {
      const QRCode = deviceDiscoveryResponse.imageUrl
      setQrImage(QRCode)
    }
  }

  function handleXmlFileDownload () {
    if (deviceDiscoveryResponse.xmlString) {
      const fileContent = deviceDiscoveryResponse.xmlString
      const blob = new Blob([fileContent], { type: 'text/xml' })
      if (blob.size > 0) {
        saveAs(blob, 'devicefile' + '.xml', true)
      }
    }
  }

  const headers = [
    { label: 'model', key: 'model' },
    { label: 'serialNumber', key: 'serialNumber' }
  ]
  const csvReport = {
    data: devices,
    headers,
    filename: 'Devices_Report.csv'
  }
  const fileReaderOnLoad = useCallback((event) => {
    if (event.target.result === null) {
      return null
    }
    const data = event.target.result
      ? processCsvFile(event.target.result)
      : ''
  }, [])

  function processCsvFile (input) {
    const result = []
    let [headerLine, ...lines] = input.split(/\r\n|\n/)
    lines = lines.filter(line => line !== '')
    const headers = headerLine.split(',')
    lines.forEach(line => {
      const lineCells = line.split(',')
      const lineData = {}
      headers.forEach((column, i) => {
        lineData[column.replace(/['"]+/g, '')] = lineCells[i].replace(/['"]+/g, '')
      })
      if (lineData.model && lineData.serialNumber) {
        lineData.type = 'Mobile Computer'
        lineData.alias = lineData.model + lineData.serialNumber
        result.push(lineData)
      }
    })
    updateDevices([...devices, ...result])
  }

  const processFile = useCallback((file) => {
    const reader = new FileReader()
    reader.onload = fileReaderOnLoad

    if (file) {
      const fileParts = file.name.split('.')
      const fileType = fileParts.length > 0 ? fileParts[fileParts.length - 1] : ''

      if (fileType === 'csv') {
        reader.readAsText(file)
      }
    }
  }, [fileReaderOnLoad])

  return (
    <ActionBarSection style={{ justifyContent: 'flex-end' }}>
      <AddDevicesModalOpen
        handleDeviceListSubmit={handleDeviceListSubmit}
        handleDeviceDiscovery={handleDeviceDiscovery}
        handleCloseModal={handleCloseModal}
        handleDeviceNameChange={handleDeviceNameChange}
        handleDeviceModelChange={handleDeviceModelChange}
        handleSerialNumberChange={handleSerialNumberChange}
        handleAddDevice={handleAddDevice}
        handleDeviceRemoveClick={onDeviceRowDeleteClick}
        handleDeviceTypeChange={handleDeviceTypeChange}
        addDevicesModalOpen={addDevicesModalOpen}
        deviceTypes={deviceTypes}
        loading={loading}
        deviceName={deviceName}
        deviceModel={deviceModel}
        serialNumber={serialNumber}
        devices={devices}
        stopDiscoveryDevice={stopDiscoveryDevice}
        handleToStopDeviceDiscovery={handleToStopDeviceDiscovery}
        csvReport={csvReport}
        processFile={processFile}
        Item={Item}
      />
      <DeviceDiscoveryModalOpen
        handleXmlFileDownload={handleXmlFileDownload}
        deviceDiscoveryModalOpen={deviceDiscoveryModalOpen}
        handleDiscoveryCloseModal={handleDiscoveryCloseModal}
        handleDeviceDiscoveryModalOpen={handleDeviceDiscoveryModalOpen}
        qrImage={qrImage}
        loading={loading}
      />
      <ActionContainer
        onClick={handleDeviceOpenModal}
      >
        <StyledIcon
          name='badge-plus'
          root='common'
          size='xlarge'
        />
        <ActionText>
          Add devices
        </ActionText>
      </ActionContainer>
    </ActionBarSection>
  )
}

function Render ({ value }) {
  return <p>{value}</p>
}

function AddDevicesModalOpen ({
  handleDeviceListSubmit,
  handleDeviceDiscovery,
  handleCloseModal,
  addDevicesModalOpen,
  handleDeviceNameChange,
  handleDeviceModelChange,
  handleSerialNumberChange,
  handleAddDevice,
  handleDeviceTypeChange,
  handleDeviceRemoveClick,
  devices,
  loading,
  deviceTypes,
  deviceName,
  deviceModel,
  serialNumber,
  stopDiscoveryDevice,
  handleToStopDeviceDiscovery,
  csvReport,
  processFile,
  Item
}) {
  function ActionClickRender (row) {
    return (
      <ActionContainer
        onClick={() => handleDeviceRemoveClick(row.rowData)}
      >
        <StyledIcon
          name='delete'
          root='common'
          size='xlarge'
        />
      </ActionContainer>)
  }
  
  const columns = [
    { field: 'type', header: 'Type', initialWidth: '20%', render: Render },
    { field: 'alias', header: 'Alias', initialWidth: '20%', render: Render },
    { field: 'model', header: 'Model', initialWidth: '15%', render: Render },
    { field: 'serialNumber', header: 'Serial Number', initialWidth: '25%', render: Render },
    { field: '', header: 'Action', initialWidth: '15%', render: ActionClickRender }
  ]
  return (
    <StyledModal size='mini' closeIcon={true} onClose={handleCloseModal} open={addDevicesModalOpen}>
      <Modal.Header>
        {loading && <Loader loading={loading} style={{ position: 'relative' }} />}
        <Title style={{ textTransform: 'none' }}>Add devices</Title>
      </Modal.Header>
      <StyledModalContent>
        <p>Fill the next fields to add a device or drag and drop and drop devices.</p>
        <FlexContainer>
          <FlexGrow3>
            <StyledDropdown
              label='Device Type'
              indicator='required'
              fluid={true}
              placeholder='Mobile Computer'
              defaultValue={0}
              options={deviceTypes}
              onChange={handleDeviceTypeChange}
            />
          </FlexGrow3>
        </FlexContainer>
        <FlexContainer>
          {config.persistAddDevices &&
            <FlexGrow3>
              <Input
                fluid={true}
                label='Device Name'
                value={deviceName}
                onChange={(val) => handleDeviceNameChange(val)}
              />
            </FlexGrow3>}
          <FlexGrow3>
            <Input
              fluid={true}
              value={deviceModel}
              label='Device Model'
              onChange={(val) => handleDeviceModelChange(val)}
              indicator='required'
            />

          </FlexGrow3>
          <FlexGrow3>
            <Input
              fluid={true}
              label='Serial Number'
              value={serialNumber}
              onChange={(val) => handleSerialNumberChange(val)}
              indicator='required'
            />
          </FlexGrow3>
          <StyledButton disabled={(!deviceModel || !serialNumber)} content='Add' onClick={handleAddDevice} />
        </FlexContainer>

        <FlexContainer>
          <StyledTitle>Devices to add</StyledTitle>
          <ExportButton>
            <CSVLink {...csvReport}>
              <Item
                icon='file-download'
                iconSize='medium'
              />
            </CSVLink>
          </ExportButton>
        </FlexContainer>
        <DevicesContainer>
          <TableContainer>
            <StyledDataTable
              loading={loading}
              data={devices}
              searchPlaceholder='Search for software'
              scrollHeight='25vh'
              scrollable={true}
            >
              {columns
                .map(key =>
                  <StyledDataTable.Column
                    key={key.field}
                    field={key.field}
                    initialWidth={key.initialWidth}
                    renderer={key.render}
                    header={key.header}
                    sortable={true}
                  />)}
            </StyledDataTable>
            <StyledFileDrop
              label=''
              acceptedTypes={['.csv', '.xlsx']}
              buttonText='Import'
              onAccepted={(files) => processFile(files[0])}
            />
          </TableContainer>
        </DevicesContainer>
      </StyledModalContent>
      <Modal.Footer>
        {/* <Button
          fluid={true} type='primary' size='small' content={stopDiscoveryDevice ? 'Stop Device Discovery' : 'Start Device Discovery'}
          onClick={() => stopDiscoveryDevice ? handleToStopDeviceDiscovery() : handleDeviceDiscovery()}
        /> */}
        <Button fluid={true} type='secondary' size='small' content='Close' onClick={handleCloseModal} />
        <Button
          fluid={true} type='primary' size='small' content='Submit'
          onClick={() => handleDeviceListSubmit()}
        />
      </Modal.Footer>
    </StyledModal>
  )
}

function DeviceDiscoveryModalOpen ({
  deviceDiscoveryModalOpen,
  handleXmlFileDownload,
  handleDiscoveryCloseModal,
  handleDeviceDiscoveryModalOpen,
  loading,
  qrImage
}) {
  handleDeviceDiscoveryModalOpen()
  return (
    <StyledModal size='mini' closeIcon={true} onClose={handleDiscoveryCloseModal} open={deviceDiscoveryModalOpen}>
      <Modal.Header>
        {loading && <Loader loading={loading} style={{ position: 'relative' }} />}
        <Title style={{ textTransform: 'none' }}>Add devices</Title>
      </Modal.Header>
      <StyledModalContent>
        <img src={qrImage} style={{ width: '50%', display: 'block', margin: 'auto' }} />
        <StyledDiv>
          <a href={qrImage} download><Icon root='common' name='file-download' size='large' style={{ marginRight: '20px' }} /></a>
          <Icon root='common' name='save' size='large' onClick={() => handleXmlFileDownload()} />
        </StyledDiv>
      </StyledModalContent>
      <Modal.Footer>
        <Button fluid={true} type='secondary' size='small' content='Close' onClick={handleDiscoveryCloseModal} />
      </Modal.Footer>
    </StyledModal>
  )
}

export default React.memo(AddDevices)
