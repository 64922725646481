import { pipe } from 'ramda'
import { connect } from 'react-redux'
import OpintelLicenses from './opintelLicenses'
import mapStateToProps from './opintelLicenses.selector'
import { OpintelLicensesActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getOpintelLicenses: () => dispatch(OpintelLicensesActions.getOpintelLicenses()),
  reset: () => dispatch(OpintelLicensesActions.reset())
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(OpintelLicenses)
