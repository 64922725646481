import { put, call, takeLatest, all } from 'redux-saga/effects'
import { LicenseImportTypes, LicenseImportActions } from './actions'
import { LicenseImportApi } from '../../../Services'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from '../../../Themes/ScufStyledComponents'
import React from 'react'
import { SessionExpiryActions } from '../../SessionExpiry/store'
import config from 'Config/AppConfig'
import {
  SITE_ID_STANDALONE,
  SITE_NAME_STANDALONE
} from 'Utils/constants'

export function * getSites (api) {
  try {
    const response = yield call(api.getSites)
    if (response.ok) {
      yield all([
        put(LicenseImportActions.updateSites(getData(response.data))),
        put(LicenseImportActions.updateOrganization(getOrganization(response.data)))
      ])
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(LicenseImportActions.updateSitesFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(LicenseImportActions.updateSitesFailure())
    ])
  }
}

function getData (data) {
  const response = []
  for (let i = 0; i < data.length; i++) {
    response[i] = {
      text: data[i].organizationalUnitHierarchy,
      value: i,
      data: {
        SiteId: data[i].organizationalUnitIdentifier.organizationalUnitGuid,
        SiteName: data[i].organizationalUnitHierarchy
      }
    }
  }
  return response
}

function getOrganization (data) {
  const organization = {}
  if (data[0].parentIdentifier) {
    organization.orgName = data[0].parentIdentifier.organizationalUnitName
    organization.orgId = data[0].parentIdentifier.organizationalUnitGuid
    return organization
  } else {
    organization.orgName = data[0].organizationalUnitIdentifier.organizationalUnitName
    organization.orgId = data[0].organizationalUnitIdentifier.organizationalUnitGuid
    return organization
  }
}

export function * importLicense (api, { activationId, licenseNo, siteId, siteName }) {
  try {
    const payLoad = {
      ActivationId: activationId,
      Count: licenseNo,
      SiteName: siteName,
      SiteId: siteId
    }
    if(config.nonSinaps)
    {
      payLoad.siteId = SITE_ID_STANDALONE
      payLoad.siteName = SITE_NAME_STANDALONE
    }
    const response = yield call(api.importLicense, payLoad)
    if (response.ok) {
      yield all([
        put(LicenseImportActions.licenseImportResult(response.data))
      ])
      if (response.data.statusCode === '200' || response.data.statusCode === '204') {
        toast(<SuccessToast message='License Imported Successfully' />)
        yield all([
          put(LicenseImportActions.importLicenseComplete())
        ])
      } else if (response.data.statusCode === '202') {
        toast(<SuccessToast message='Import License request has been accepted. This will be processed in some time.' />)
        yield all([
          put(LicenseImportActions.importLicenseComplete())
        ])
      } else {
        let msg = 'License Import Failed'
        switch (response.data.statusCode) {
          case ('100'):
            msg = 'Activation ID invalid'
            break
          case ('101'):
            msg = 'Requested count of license is not available'
            break
          case ('102'):
            msg = 'Activation ID expired'
            break
          case ('103'):
            msg = 'Activation ID in use by another organization'
            break
          case ('500'):
          default:
            msg = 'License Import Failed'
            break
        }
        toast(<ErrorToast message={msg} />)
        yield all([
          put(LicenseImportActions.importLicenseComplete())
        ])
      }
    } else {
      if(response.status == 403)
        toast(<ErrorToast message='You do not have Import License Access for this site.' />)
      else if(response.status == 401)
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      else { toast(<ErrorToast message='License Import Failed' />) }
      yield all([
        put(LicenseImportActions.importLicenseComplete())
      ])
    }
  } catch (_) {
    toast(<ErrorToast message='Error while importing license' />)
    yield all([
      put(LicenseImportActions.importLicenseComplete())
    ])
  }
}

export function * licenseImportSagas () {
  yield all([
    takeLatest(LicenseImportTypes.GET_SITES, getSites, LicenseImportApi),
    takeLatest(LicenseImportTypes.IMPORT_LICENSE, importLicense, LicenseImportApi)
  ])
}
