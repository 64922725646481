import { createStructuredSelector } from 'reselect'

const sites = ({ licenseImport }) => licenseImport.getIn(['sitesList'], [])
const loading = ({ licenseImport }) => licenseImport.getIn(['loading'], false)
const organization = ({ licenseImport }) => licenseImport.getIn(['currentOrganization'], {})
const licenseImportResult = ({ licenseImport }) => {
  return licenseImport.getIn(['licenseImportResult'], {})
}
export default createStructuredSelector({
  loading,
  sites,
  organization,
  licenseImportResult
})
