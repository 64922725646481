import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loading: null,
  getSchedules: null,
  updateSchedules: ['schedules'],
  updateSchedulesFailure: null,
  handleDelete: ['selectedSoftwares'],
  requestComplete: null
}, {
  prefix: '@SCHEDULES/'
})

export const SchedulesTypes = Types
export const SchedulesActions = Creators
