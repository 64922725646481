import React, { Component, Fragment } from 'react'

class Polling extends Component {
  componentDidMount () {
    const { pollingAction, duration } = this.props
    if (!pollingAction) {
      return
    }

    pollingAction()
    this.dataPolling = setInterval(
      () => pollingAction(),
      duration)
  }

  componentWillUnmount () {
    clearInterval(this.dataPolling)
  }

  render () {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

export default Polling
