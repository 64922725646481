import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  loading: null,
  getDeviceTypes: null,
  updateDeviceTypes: ['deviceTypes'],
  getDevicesList: null,
  updateDeviceDiscovery: ['deviceDiscoveryResponse'],
  deviceDiscovery: null,
  updateDeviceDiscoveryFailure: null,
  discoveredDevices: ['sessionId'],
  updateDiscoveredDevices: ['discoveredDeviceList'],
  updateDiscoveredDevicesFailure: null,
  addDevices: ['devices'],
  addDevicesComplete: null,
  updateDevices: ['devices'],
  updateDevicesFailure: null

}, {
  prefix: '@DEVICES/'
})

export const DevicesTypes = Types
export const DevicesActions = Creators
