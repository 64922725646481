import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Historys from './historys'
import mapStateToProps from './historys.selector'
import { HistorysActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getHistorys: () => dispatch(HistorysActions.getHistorys())
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Historys)
