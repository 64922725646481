import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  sessionExpiry: null
}, {
  prefix: '@SESSIONEXPIRY/'
})

export const SessionExpiryTypes = Types
export const SessionExpiryActions = Creators
