import { pipe } from 'ramda'
import { connect } from 'react-redux'
import AddDevices from './addDevices'
import mapStateToProps from './addDevices.selector'
import { DevicesActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  addDevices: (devices) => dispatch(DevicesActions.addDevices(devices)),
  deviceDiscovery: () => dispatch(DevicesActions.deviceDiscovery()),
  discoveredDevices: (sessionId) => dispatch(DevicesActions.discoveredDevices(sessionId)),
  getDeviceTypes: () => dispatch(DevicesActions.getDeviceTypes()),
  refreshDevices: () => dispatch(DevicesActions.getDevicesList()),
  reset: () => dispatch(DevicesActions.reset())
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(AddDevices)
