import get from 'lodash/get';

let onPremVariables = undefined;
const onPrem = global.localStorage.getItem('__OP_INTEL__');
if (onPrem !== undefined && JSON.parse(onPrem)) onPremVariables = JSON.parse(onPrem);

const config = {
  debug: (process.env.REACT_APP_DEBUG_MODE === 'true'),
  nonSinaps: (process.env.REACT_APP_NON_SINAPS_MODE === 'true'),
  api: {
    connectedaidc: process.env.REACT_APP_API_URL,
    apiKey: process.env.REACT_APP_APIGEE_KEY
  },
  persistAddDevices: false,
  baseUrl: process.env.REACT_APP_BASE_URL,
  launchDarkly: {
    clientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENTID,
    clientType: get(onPremVariables, 'launch_darkly_clientType', process.env.REACT_APP_LAUNCH_DARKLY_CLIENTTYPE),
  },
}

export default config
