import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { SessionExpiryTypes } from './actions'

export const INITIAL_STATE = Immutable({
  sessionExpired: false
})

const reset = () => INITIAL_STATE
const sessionExpiry = state => state.merge({sessionExpired: true})

export const sessionExpiryReducer = createReducer(INITIAL_STATE, {
  [SessionExpiryTypes.SESSION_EXPIRY]: sessionExpiry,
  [SessionExpiryTypes.RESET]: reset,
})
