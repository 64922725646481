import React from 'react'
import { call } from 'ramda'
import { takeLatest, all, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from 'Themes/ScufStyledComponents'
import { SessionExpiryActions } from 'Features/SessionExpiry/store'
import { LicenseImportOnPremTypes, LicenseImportOnPremActions } from './actions'
import { LicenseImportOnPremApi } from 'Services'
import { saveAs } from 'file-saver'

const DEVICE_TYPE = 'OnPremiseOI'
const SITE_ID = '5fd244106d09b0ffe4e59b21'

export function * importLicense ({ activationId, hostId, downloadType, devicesCount }) {
  try {
    // Move to constants
    // Include EMail ID fieldin the UI - No Email ID FIeld
    // Stop closing the moal when clicked away
    const payload = {
      activationId,
      uniqueId: hostId,
      count: devicesCount,
      deviceType: DEVICE_TYPE,
      siteId: SITE_ID,
      downloadType
    }
    const response = yield call(LicenseImportOnPremApi.importLicense, payload)
    if (response.ok) {
      if (response.data.licenseResponseBundle.statusCode === '200') {
        if (response.data.downloadXml.xmlContent) {
          const fileContent = atob(response.data.downloadXml.xmlContent)
          const blob = new Blob([fileContent], { type: 'text/xml' })
          if (blob.size > 0) {
            saveAs(blob, response.data.downloadXml.xmlName+'.xml', true)
          }
        }
        if (downloadType === 0) {
          toast(<SuccessToast message='License file Generated Successfuly' />)
        } else if (downloadType === 1) {
          toast(<SuccessToast message='License File sent in Email' />)
        } else {
          toast(<SuccessToast message='License file generated and sent in Email' />)
        }
        yield all([
          put(LicenseImportOnPremActions.importLicenseComplete())
        ])
      } else {
        let msg = 'License Import Failed'
        switch (response.data.licenseResponseBundle.statusCode) {
          case ('100'):
            msg = 'Activation ID invalid'
            break
          case ('101'):
            msg = 'Requested count of license is not available'
            break
          case ('102'):
            msg = 'Activation ID expired'
            break
          case ('103'):
            msg = 'Activation ID in use by another organization'
            break
          case ('114'):
            msg = 'Bundle creation successful but sending mail failed'
            break
          case ('500'):
          default:
            msg = 'License Import Failed'
            break
        }
        toast(<ErrorToast message={msg} />)
        yield all([
          put(LicenseImportOnPremActions.importLicenseComplete())
        ])
      }
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      } else { toast(<ErrorToast message='License Import Failed' />) }
      yield all([
        put(LicenseImportOnPremActions.importLicenseComplete())
      ])
    }
  } catch (_) {
    toast(<ErrorToast message='Error while importing license' />)
    yield all([
      put(LicenseImportOnPremActions.importLicenseComplete())
    ])
  }
}

export function * licenseImportOnPremSagas () {
  yield all([
    takeLatest(LicenseImportOnPremTypes.IMPORT_LICENSE, importLicense)
  ])
}
