import { all, fork } from 'redux-saga/effects'

import { licensesSagas } from '../Features/Licenses'
import { licenseInstallerSagas } from '../Features/LicenseInstaller'
import { schedulesSagas } from '../Features/Schedules'
import { historysSagas } from '../Features/Historys'
import { devicesSagas } from '../Features/Devices'
import { licenseImportSagas } from '../Features/LicenseImport'
import { licenseImportOnPremSagas } from '../Features/LicenseImportOnPrem'
import { othersSagas } from '../Features/Others'
import { notificationsSagas } from '../Features/NotificationBell'
import { opintelLicensesSagas } from 'Features/OpintelLicenses'

export default function * root () {
  yield all([
    fork(licensesSagas),
    fork(licenseInstallerSagas),
    fork(schedulesSagas),
    fork(historysSagas),
    fork(licenseImportSagas),
    fork(licenseImportOnPremSagas),
    fork(devicesSagas),
    fork(othersSagas),
    fork(notificationsSagas),
    fork(opintelLicensesSagas)
  ])
}
