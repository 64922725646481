import React, { useEffect, useState, useRef, useCallback } from 'react'
import config from 'Config/AppConfig'
import {
  StyledDeviceContainer,
  StyledInput,
  StyledParagraph,
  StyledDataTable,
  ActionBarContainer,
  ActionBarSection,
  StyledLabel,
  ErrorContent,
  ErrorTitle,
  ErrorInfo,
  SiteSelectionContainer
} from './devices.styles'
import AddDevices from 'Features/Devices/components/AddDevices'
import { Checkbox, Radio, Select, Icon } from '@scuf/common'
import { SITE_FILTERED_DEVICE_COUNT, ORG_FILTERED_DEVICE_COUNT, ORG_FILTER_TYPE, SITE_FILTER_TYPE } from '../../licenseInstaller.constants'
let columns = [
  { field: 'name', header: 'Alias', render: Render },
  { field: 'displayModel', header: 'Model', render: Render },
  { field: 'serialNumber', header: 'Serial Number', render: Render }
]

if (!config.nonSinaps) {
  columns = [...columns, { field: 'status', header: 'State', initialWidth: '10%', render: RenderState }]
}

function Render({ value }) {
  return <p>{value}</p>
}

function RenderState({ value }) {
  switch (value) {
    case 'ACTIVE': return <p>Active</p>
    case 'LOST': return <p>Lost</p>
    case 'OUT_FOR_REPAIR': return <p>Out for repair</p>
    case 'OUT_OF_SERVICE': return <p>Out of service</p>
    default: return <p>Unknown</p>
  }
}
export const SelectMode = { 'sites': 1, 'devices': 2 }
const Devices = ({
  devices,
  bundleDevices,
  loadingDevices,
  selectedBundleName,
  selectedDevicesList,
  siteId,
  getDevices,
  updateBundleName,
  updateSelectedDevices,
  getSites,
  sitesList,
  deviceFilterType,
  devicesCount,
  createBundleFilter,
  mode
}) => {
  const [isEditMode, setEditMode] = useState(mode == 'edit' ? true : false)
  const [bundleName, setBundleName] = useState(selectedBundleName)
  const [selectedDevices, setSelectedDevices] = useState(selectedDevicesList)
  const [selectMode, setSelectMode] = useState(deviceFilterType)
  const [selectedSite, setSelectedSite] = useState('')
  const [includeChildSites, setIncludeChildSites] = useState(createBundleFilter?.includeChildSites == 'true')
  const bundleNameRef = useRef()
  const [isDone, setIsDone] = useState(false)
  const [dataTableData, setDataTableData] = useState(devices)
  const [scrollIndex, setScrollIndex] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')
  const [activePage, setActivePage] = useState(1)


  useEffect(() => {
    let selectedSiteId
    if (!isEditMode && siteId && !config.nonSinaps) {
      getSites(siteId)
      selectedSiteId = createBundleFilter.getIn(['siteId', '$in'])?.[0];
    }
    else if (isEditMode && siteId) {
      getDevices(siteId, true, ORG_FILTER_TYPE, 1, [])
      setSelectMode(ORG_FILTER_TYPE)
    }
    if (selectedSiteId) {
      const siteInfo = sitesList.find((d) => d.value.id == selectedSiteId)
      setSelectedSite(siteInfo?.value)
    }
  }, [getSites, siteId, isEditMode])

  useEffect(() => {
    if (!bundleName && !selectMode) {
      bundleNameRef.current.focus()
    }
  }, [searchedValue])

  useEffect(() => {
    let newDevices = [...selectedDevices, ...devices]
    if (searchedValue) {
      newDevices = devices
    }
    setIsDone(devices.length == devicesCount)
    setDataTableData(removeDuplicates(newDevices))
  }, [devices])

  useEffect(() => {
    setSelectedDevices(selectedDevicesList)
  }, [selectedDevicesList])

  useEffect(() => {
    setBundleName(selectedBundleName)
  }, [selectedBundleName])

  const isSites = selectMode === SITE_FILTER_TYPE
  const isDeviceList = selectMode === ORG_FILTER_TYPE
  const paginationLimit = isDeviceList ? ORG_FILTERED_DEVICE_COUNT : SITE_FILTERED_DEVICE_COUNT
  const handleBundleName = useCallback(
    (val) => {
      const inputBundleName = val.toString()
      const validName = inputBundleName.replace(/[^0-9a-zA-Z +-.@_]+/g, '')
      updateBundleName(validName)
      setBundleName(validName)
    }, [updateBundleName, setBundleName])

  const handleSelectedDevices = useCallback(
    (rows, isUpdate = false) => {
      let isUncheckedPrevDevice = false;
      if (bundleDevices.length > 0) {
        bundleDevices.forEach(bundleDevice => {
          const found = rows.find(row => row.serialNumber === bundleDevice)
          if (found === undefined) {
            isUncheckedPrevDevice = true;
            const dev = devices.find(device => device.serialNumber === bundleDevice)
            if (dev !== undefined) { rows = [...rows, dev] }
          }
        })
      }
      if (!isEditMode || (isEditMode && (!isUncheckedPrevDevice || isUpdate))) {
        rows = removeDuplicates(rows)
        setSelectedDevices(rows)
        updateSelectedDevices(rows)
        updateCheckedDevicesPos(rows)
      }
    }, [devices, bundleDevices, setSelectedDevices, updateSelectedDevices])

  const updateCheckedDevicesPos = (rows) => {
    let copy_devices = []
    let rowIndex = []
    rows.forEach(b => {
      const found = devices.findIndex((d) => d.serialNumber === b.serialNumber)
      rowIndex.push(found)
    })
    copy_devices = devices.filter((d, i) => !rowIndex.includes(i));
    setDataTableData([...rows, ...copy_devices])
  }
  function removeDuplicates(data) {
    const unique = []
    data.forEach(element => {
      const contains = unique.some(elem => {
        return JSON.stringify(element) === JSON.stringify(elem)
      })
      if (!contains) {
        unique.push(element)
      }
    })
    return unique
  }

  function updateDevices() {
    let alldevices = selectedDevicesList.map(device => ({
      type: 'mobilecomputer',
      alias: device.name == null ? device.alias : device.name,
      name: device.name == null ? device.alias : device.name,
      model: device.displayModel == null ? device.model : device.displayModel,
      displayModel: device.displayModel == null ? device.model : device.displayModel,
      serialNumber: device.serialNumber
    }))
    return alldevices;
  }

  let devList = updateDevices()

  const deviceDataFormat = JSON.stringify(devList)

  const getDevicesData = (optValue) => {
    if (optValue == ORG_FILTER_TYPE) {
      getDevices(siteId, true, optValue, 1, [])
    } else {
      setSelectedSite('')
      setIncludeChildSites(false)
      updateSelectedDevices([])
    }
    setActivePage(1)
    setIsDone(false)
    setSelectMode(optValue)
    setSelectedDevices([])
    setSearchedValue('')
  }

  const handleScroll = ({ first, rows }) => {
    const pages = Math.ceil(devicesCount / (paginationLimit || 1) || 1);
    const currentRows = devices.length || 0;
    const currentPage = (Math.ceil(currentRows / paginationLimit || 1) || 1) + 1;

    if (currentRows < devicesCount && currentPage <= pages && first >= scrollIndex) {
      const site = isDeviceList ? siteId : selectedSite?.id;
      getDevices(site, includeChildSites, selectMode, currentPage, dataTableData, searchedValue)
    }
    if (currentRows == devicesCount) {
      setIsDone(true);
    }
    setScrollIndex(first);
  }


  const slicedData = ({ first, rows }) => {
    let last = first + rows;
    if (first + rows > devicesCount) {
      last = devicesCount;
    }
    setDataTableData([...devices].slice(first, last));
  };

  const handleSearch = (searchString) => {
    const site = isDeviceList ? siteId : selectedSite?.id;
    setSearchedValue(searchString)
    if (searchString) {
      getDevices(site, includeChildSites, selectMode, 1, [], searchString)
    } else {
      getDevices(site, includeChildSites, selectMode, 1, [], '')
    }
  }
  const handlePageChange = (page) => {
    setActivePage(page);
    const site = isDeviceList ? siteId : selectedSite?.id;
    getDevices(site, includeChildSites, selectMode, page, [], searchedValue)
  }

  return (
    <StyledDeviceContainer>
      <ActionBarContainer>
        <ActionBarSection flexDirection='column'>
          <StyledInput
            ref={bundleNameRef}
            type='text'
            indicator='required'
            label='Bundle Name'
            value={bundleName}
            placeholder='Type your new bundle name'
            onChange={(val) => handleBundleName(val)}
          />
        </ActionBarSection>
        {config.nonSinaps && <AddDevices devicesList={deviceDataFormat} />}
      </ActionBarContainer>
      {!isEditMode && !config.nonSinaps && <>
        <StyledLabel>How do you want to select the devices to be updated?</StyledLabel>
        <span style={{ margin: '1rem' }}>
          <Radio
            checked={isSites}
            disabled={loadingDevices}
            label={'By Site'}
            onChange={() => { getDevicesData(SITE_FILTER_TYPE) }}
          />
          <Radio
            checked={isDeviceList}
            disabled={loadingDevices}
            label={'From List'}
            onChange={() => { getDevicesData(ORG_FILTER_TYPE) }}
          />
          {isSites &&
            <>
              <span style={{ marginLeft: '4em' }}>
                <Checkbox
                  disabled={loadingDevices}
                  checked={includeChildSites}
                  label={'Include children sites'}
                  onChange={checked => { setIncludeChildSites(checked); setSearchedValue(''); getDevices(selectedSite.id, checked, selectMode, 1, []) }}
                />
              </span>
            </>
          }
        </span>
        {isSites &&
          <SiteSelectionContainer>
            <Select
              label={'Choose the site where the update will be applied:'}
              fluid
              key={selectedSite}
              value={selectedSite}
              onChange={site => { setSelectedSite(site); getDevices(site.id, includeChildSites, selectMode, 1); }}
              options={sitesList}
              search={true}
              placeholder={'Select a site...'}
            />
          </SiteSelectionContainer>}
      </>
      }
      {(isDeviceList && devices.length > 0) && <StyledParagraph>Select the devices you want to assign to this bundle, Search is enabled for devices. On Editing a bundle the existing devices in a bundle are already marked.</StyledParagraph>}
      {(isDeviceList && selectedDevices.length > 0) && <strong> Selected Devices: {selectedDevices.length} </strong>}
      {!config.nonSinaps && <>{(devicesCount !== 0 && !searchedValue && (isSites && selectedSite) || isDeviceList) || (searchedValue) ?
        <>
          <StyledDataTable
            data={dataTableData}
            loading={searchedValue ? false : loadingDevices}
            search={true}
            searchPlaceholder='Search by Alias or Serial Number...'
            selection={selectedDevices}
            totalRecords={devicesCount}
            selectionMode={isDeviceList ? 'multiple' : ''}
            onSelectionChange={(rows) => { handleSelectedDevices(rows, !rows.length && selectedDevices.length) }}
            scrollHeight='20vh'
            scrollable={true}
            rows={dataTableData.length}
            onGlobalFilter={handleSearch}
            virtualRowHeight={48}
          >{columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
          </StyledDataTable>
          {devices.length > 0 &&
            <StyledDataTable.Pagination
              totalItems={devicesCount}
              itemsPerPage={paginationLimit}
              activePage={activePage}
              showDisplayDetails
              onPageChange={handlePageChange}
            />
          }
        </> :
        <StyledDeviceContainer>
          <>
            <ErrorContent>
              <Icon root='common' name='ip-conflict' size='large' color='#C0C0C0' />
              <ErrorTitle>No Devices Selected</ErrorTitle>
              <ErrorInfo>Your current selection criteria does not include any devices</ErrorInfo>
            </ErrorContent>
          </>
        </StyledDeviceContainer>

      }</>}

      {config.nonSinaps
        && <StyledDataTable
          data={selectedDevicesList}
          loading={loadingDevices}
          search={true}
          searchPlaceholder='Search for devices'
          onSelectionChange={handleSelectedDevices}
          scrollHeight='20vh'
          scrollable={true}
        >{columns
          .map(key =>
            <StyledDataTable.Column
              key={key.field}
              field={key.field}
              initialWidth={key.initialWidth}
              renderer={key.render}
              header={key.header}
              sortable={true}
            />)}
        </StyledDataTable>}

    </StyledDeviceContainer>
  )
}

export default Devices