import api from './ApiSauceInstance'

import { LicensesService } from '../Features/Licenses'
import { LicenseInstallerService } from '../Features/LicenseInstaller'
import { SchedulesService } from '../Features/Schedules'
import { HistorysService } from '../Features/Historys'
import { LicenseImportService } from '../Features/LicenseImport'
import { LicenseImportOnPremService } from '../Features/LicenseImportOnPrem'
import { DevicesService } from '../Features/Devices'
import { OthersService } from '../Features/Others'
import { NotificationsService } from '../Features/NotificationBell'
import { OpintelLicensesService } from '../Features/OpintelLicenses'

export const LicensesApi = new LicensesService(api)
export const LicenseInstallerApi = new LicenseInstallerService(api)
export const SchedulesApi = new SchedulesService(api)
export const HistorysApi = new HistorysService(api)
export const LicenseImportApi = new LicenseImportService(api)
export const LicenseImportOnPremApi = new LicenseImportOnPremService(api)
export const DevicesApi = new DevicesService(api)
export const OthersApi = new OthersService(api)
export const NotificationsApi = new NotificationsService(api)
export const OpintelLicensesApi = new OpintelLicensesService(api)
