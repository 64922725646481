import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { OthersTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  othersList: [],
  exportInProgress: false,
  exportBundle: null
})

const loading = state => state.merge({ loading: true })
const updateOthers = (state, { others }) => state.merge({ othersList: others, loading: false })
const updateOthersListFailure = state => state.merge({ othersList: [], loading: false })
// const othersBundleExport = (state, { licenseBundle }) => state.merge({ LicenseBundle: licenseBundle, loading: false })
// const updateLicenseBundleExportFailure = state => state.merge({ loading: false })
const updateExportInProgress = state => state.merge({ exportInProgress: !state.exportInProgress })
const reset = () => INITIAL_STATE

export const othersReducer = createReducer(INITIAL_STATE, {
  [OthersTypes.GET_OTHERS_LIST]: loading,
  [OthersTypes.LOADING]: loading,
  [OthersTypes.UPDATE_OTHERS]: updateOthers,
  [OthersTypes.UPDATE_OTHERS_LIST_FAILURE]: updateOthersListFailure,
  [OthersTypes.UPDATE_EXPORT_IN_PROGRESS]: updateExportInProgress,
  [OthersTypes.RESET]: reset
})
