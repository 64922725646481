import React from 'react'
import { Button } from '@scuf/common'
import { StyledModal, StyledDiv, StyledBodyDesc, StyledFooter } from './licenseReturn.styles'

const LicenseReturnModal = ({
  openModal,
  handleClose
}) => {
  const handelReturn = () => {

  }
  return (
    <StyledModal open={openModal} closeIcon={true} onClose={handleClose} size='small' closeOnDimmerClick={false}>
      <StyledModal.Header>
        <StyledDiv>
          License Return
        </StyledDiv>
      </StyledModal.Header>
      <StyledModal.Content>
        <StyledBodyDesc>
          This will return the number of licenses you type in the licenses field below to the Flexera platform.
        </StyledBodyDesc>
        <div />
      </StyledModal.Content>
      <StyledModal.Footer>
        <StyledFooter>
          <Button
            type='secondary'
            size='medium'
            content='Cancel'
            onClick={handleClose}
          />
          <Button
            type='primary'
            size='medium'
            content='Return'
            onClick={handelReturn}
          />
        </StyledFooter>
      </StyledModal.Footer>
    </StyledModal>
  )
}

export default LicenseReturnModal
