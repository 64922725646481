import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { NotificationsTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  notificationsList: [],
  notificationIdsList: []
})

const loading = state => state.merge({ loading: true })
const updateNotificationsList = (state, { notifications }) => state.merge({ notificationsList: notifications, loading: false })
const updateNotificationFailure = state => state.merge({ notificationsList: [], loading: false })
const statusUpdateNotifications = (state, { notificationIds }) => state.merge({ notificationIdsList: notificationIds, loading: false })
const requestComplete = state => { return state.merge({ loading: false }) }

export const notificationsReducer = createReducer(INITIAL_STATE, {
  [NotificationsTypes.GET_NOTIFICATIONS]: loading,
  [NotificationsTypes.UPDATE_NOTIFICATIONS]: updateNotificationsList,
  [NotificationsTypes.UPDATE_NOTIFICATION_FAILURE]: updateNotificationFailure,
  [NotificationsTypes.STATUS_UPDATE_NOTIFICATIONS]: statusUpdateNotifications,
  [NotificationsTypes.STATUS_UPDATE_NOTIFICATIONS_COMPLETE]: requestComplete
})
