import React, { useEffect, useState } from 'react'
import Software from '../Software'
import Devices from '../Devices'
import Schedule from '../Schedule'
import Confirmation from '../Confirmation'
import config from 'Config/AppConfig'
import { SOFTWARE_TAB, DEVICES_TAB, CONFIRMATION_TAB, SCHEDULE_TAB, SCHEDULE, INSTALL_NOW } from 'Features/LicenseInstaller/licenseInstaller.constants'

export function useTabsList({ selectedSoftwares, selectedDevicesList, bundleName, scheduleType, scheduleTimezone, scheduleFromDate, scheduleFromTime, scheduleToDate, scheduleToTime, scheduleFromDatetime, scheduleToDatetime, tabIndex, mode }) {
  const [activeInstallerSection, setActiveInstallerSection] = useState(tabIndex); // earlier,in my changes it was (1)
  useEffect(() => { setActiveInstallerSection(tabIndex) }, [tabIndex]);

  if (config.nonSinaps) {
    return [
      activeInstallerSection,
      setActiveInstallerSection,
      [{
        title: 'Software',
        active: () => activeInstallerSection === SOFTWARE_TAB,
        completed: () => activeInstallerSection > SOFTWARE_TAB,
        handleClick: () => setActiveInstallerSection(SOFTWARE_TAB)
      },
      {
        title: 'Devices',
        active: () => activeInstallerSection === DEVICES_TAB,
        completed: () => activeInstallerSection > DEVICES_TAB,
        handleClick: () => setActiveInstallerSection(DEVICES_TAB)
      },
      {
        title: 'Confirmation',
        active: () => activeInstallerSection === CONFIRMATION_TAB,
        completed: () => activeInstallerSection > CONFIRMATION_TAB,
        handleClick: () => setActiveInstallerSection(CONFIRMATION_TAB),
        disabled: () => selectedDevicesList.length === 0 || (!bundleName)
      }],
      {
        1: <Software selectedSoftwares={selectedSoftwares} />,
        2: <Devices mode={mode} />,
        3: <Confirmation />
      }
    ]
  } else if (tabIndex === SCHEDULE_TAB || tabIndex === CONFIRMATION_TAB) {
    return [
      activeInstallerSection,
      setActiveInstallerSection,
      [{
        title: 'Software',
        active: () => activeInstallerSection === SOFTWARE_TAB,
        completed: () => activeInstallerSection > SOFTWARE_TAB,
        handleClick: () => setActiveInstallerSection(SOFTWARE_TAB),
        disabled: () => tabIndex === SCHEDULE_TAB || tabIndex === CONFIRMATION_TAB
      },
      {
        title: 'Devices',
        active: () => activeInstallerSection === DEVICES_TAB,
        completed: () => activeInstallerSection > DEVICES_TAB,
        handleClick: () => setActiveInstallerSection(DEVICES_TAB),
        disabled: () => tabIndex === SCHEDULE_TAB || tabIndex === CONFIRMATION_TAB
      },
      {
        title: 'Schedule',
        active: () => activeInstallerSection === SCHEDULE_TAB,
        completed: () => activeInstallerSection > SCHEDULE_TAB,
        handleClick: () => setActiveInstallerSection(SCHEDULE_TAB),
        disabled: () => activeInstallerSection < SCHEDULE_TAB && selectedDevicesList.length === 0 && (!bundleName)
      },
      {
        title: 'Confirmation',
        active: () => activeInstallerSection === CONFIRMATION_TAB,
        completed: () => activeInstallerSection > CONFIRMATION_TAB,
        handleClick: () => setActiveInstallerSection(CONFIRMATION_TAB),
        disabled: () => selectedDevicesList.length === 0 || ((scheduleType === SCHEDULE || scheduleType === INSTALL_NOW) && (!scheduleTimezone || !scheduleFromDate || !scheduleFromTime || !scheduleToDate || !scheduleToTime))
      }],
      {
        1: <Software selectedSoftwares={selectedSoftwares} />,
        2: <Devices  mode={mode} />,
        3: <Schedule scheduleDetails={scheduleType} />,
        4: <Confirmation />
      }
    ]
  } else {
    return [
      activeInstallerSection,
      setActiveInstallerSection,
      [{
        title: 'Software',
        active: () => activeInstallerSection === SOFTWARE_TAB,
        completed: () => activeInstallerSection > SOFTWARE_TAB,
        handleClick: () => setActiveInstallerSection(SOFTWARE_TAB)
      },
      {
        title: 'Devices',
        active: () => activeInstallerSection === DEVICES_TAB,
        completed: () => activeInstallerSection > DEVICES_TAB,
        handleClick: () => setActiveInstallerSection(DEVICES_TAB)
      },
      {
        title: 'Confirmation',
        active: () => activeInstallerSection === CONFIRMATION_TAB,
        completed: () => activeInstallerSection > CONFIRMATION_TAB,
        handleClick: () => setActiveInstallerSection(CONFIRMATION_TAB),
        disabled: () => selectedDevicesList.length === 0 || (!bundleName)
      }],
      {
        1: <Software selectedSoftwares={selectedSoftwares} />,
        2: <Devices mode={mode} />,
        3: <Confirmation />
      }
    ]
  } 
}
