import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Devices from './devices'
import mapStateToProps from './devices.selector'
import { DevicesActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getDevicesList: () => dispatch(DevicesActions.getDevicesList()),
  refreshDevices: () => dispatch(DevicesActions.getDevicesList())
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Devices)
