import styled from 'styled-components/macro'

export const StyledTableContainer = styled.div`
  tbody {
      display: block;
      max-height: calc(40vh);
      overflow: auto;
  }
  thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
  }
  th{
    font-size: .875rem !important;
    font-weight: 700 !important;
    color: #303030 !important;
    text-transform: none !important;
    padding: .25rem 1.5rem .25rem 1rem !important;    
  }
  td{
      font-family: "Honeywell Sans Web Medium" !important;
      font-size: .875rem !important;
      font-weight: 500 !important;
      word-wrap:break-word;
      color: #404040 !important;
      padding: .25rem 1.5rem .25rem 1rem !important;
  }
`
