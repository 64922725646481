import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { ImportantToast } from 'Themes/ScufStyledComponents'
import LicenseInstallerModal from 'Features/LicenseInstaller'
import LicenseReturnModal from 'Features/LicenseReturn'
import {
  LicensesContainer,
  TableContainer,
  StyledDataTable
} from './licenses.styles'
import config from 'Config/AppConfig'
import { SOFTWARE_TAB } from 'Features/LicenseInstaller/licenseInstaller.constants'

let columns = [
  { field: 'featureName', header: 'Software Name', initialWidth: '16%', render: Render },
  { field: 'activationId', header: 'Activation ID', initialWidth: '20%', render: Render },
  { field: 'availableCount', header: 'Available', initialWidth: '12%', render: Render },
  { field: 'assignedCount', header: 'Assigned', initialWidth: '11%', render: Render },
  { field: 'importedCount', header: 'Total', initialWidth: '10%', render: Render },
  { field: 'expiresOn', header: 'Maintenance Expiry', initialWidth: '17%', render: Render },
  { field: 'expirationDate', header: 'Expiration Date', initialWidth: '14%', render: Render }
]

if (!config.nonSinaps) {
  columns = [
    { field: 'featureName', header: 'Software Name', initialWidth: '14%', render: Render },
    { field: 'activationId', header: 'Activation ID', initialWidth: '20%', render: Render },
    { field: 'availableCount', header: 'Available', initialWidth: '10%', render: Render },
    { field: 'assignedCount', header: 'Assigned', initialWidth: '9%', render: Render },
    { field: 'importedCount', header: 'Total', initialWidth: '8%', render: Render },
    { field: 'expiresOn', header: 'Maintenance Expiry', initialWidth: '15%', render: Render },
    { field: 'expirationDate', header: 'Expiration Date', initialWidth: '12%', render: Render },
    { field: 'siteName', header: 'Org/Site Name', initialWidth: '12%', render: RenderSite }
  ]
}

function Render ({ value }) {
  return <p>{value}</p>
}

function RenderSite ({ value }) {
  const outValue = value.split('/')
  if (outValue.length > 1) { return <p>{(outValue[outValue.length - 1]).trim()}</p> }
  return <p>{outValue}</p>
}

const Licenses = ({
  loading,
  licenses,
  getLicenses,
  updateSelectedSoftwares,
  reset
}) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedSite, setSelectedSite] = useState('')
  const [isLicenseInstallerModalOpen, setIsLicenseInstallerModalOpen] = useState(false)
  const [isLicenseReturnModalOpen, setIsLicenseReturnModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  useEffect(() => {
    getLicenses()

    return () => reset()
  }, [getLicenses, reset])

  const { Item } = StyledDataTable.ActionBar

  const handleCloseModalCallback = useCallback(() => {
    setIsLicenseInstallerModalOpen(false)
    setSelectedRows([])
    getLicenses()
    setActiveTab(0)
  }, [setIsLicenseInstallerModalOpen, setSelectedRows, getLicenses])

  const handleInstallCallback = useCallback((index) => {
    setIsLicenseInstallerModalOpen(true)
    updateSelectedSoftwares(selectedRows)
    setActiveTab(index)
  }, [selectedRows, setIsLicenseInstallerModalOpen, updateSelectedSoftwares])

  const initActiveTab = useCallback(() => {
    setActiveTab(0)
  }, [setActiveTab])


  const handleSelectionChangedCallback = useCallback((checkedRows) => {
    const rows = [...checkedRows]
    if (rows.length > 0) { setSelectedSite(rows[0].siteId) }
    let isDiffSite = false
    let siteId = selectedSite
    if (siteId === '') { siteId = rows[0].siteId }
    for (let i = 1; i < rows.length; i++) {
      if (rows[i].siteId !== siteId) {
        rows.splice(rows[i])
        isDiffSite = true
      }
    }
    let isSameLicense = false
    for (let i = 1; i < rows.length; i++) {
      if (rows[i].featureName === rows[0].featureName && rows[i].expiresOn === rows[0].expiresOn && rows[i].expirationDate === rows[0].expirationDate) {
        rows.splice(rows[i])
        isSameLicense = true
      }
    }
    if (isDiffSite) {
      toast(<ImportantToast message='For create bundle, all Licenses have to be selected from one site' />)
      return false
    }
    else
    {
      if(isSameLicense)
      {
        toast(<ImportantToast message='License with same feature already selected' />)
        return false
      }
    }
    setSelectedRows(rows)
  }, [selectedSite, setSelectedRows])

  const handleReturnCallback = useCallback(
    () => {
      setIsLicenseReturnModalOpen(true)
    }, []
  )

  const handleClose = () => {
    setIsLicenseReturnModalOpen(false)
  }
  return (
    <LicensesContainer>
      <TableContainer>
        <StyledDataTable
          data={licenses}
          loading={loading}
          search={true}
          searchPlaceholder='Search for licenses'
          selection={selectedRows}
          selectionMode='multiple'
          onSelectionChange={(rows) => handleSelectionChangedCallback(rows)}
          scrollHeight='50vh'
          scrollable={true}
          resizableColumns={true}
          columnResizeMode='expand'
          reorderableColumns={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
                align='left'
                responsive
                flexGrow={0.75}
                flexShrink={0.75}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            {/* {config.nonSinaps &&
              <Item
                icon='redo'
                iconSize='small'
                content='Return'
                onClick={handleReturnCallback}
              />} */}
            <Item
              icon='file-download'
              iconSize='small'
              content='Install'
              onClick={() => handleInstallCallback(1)}
            />
          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
      <LicenseInstallerModal
        openModal={isLicenseInstallerModalOpen}
        handelClose={handleCloseModalCallback}
        activeTab={activeTab}
        resetActiveTab={initActiveTab}
        siteId={selectedSite}
        mode={'create'}
      />
      <LicenseReturnModal
        openModal={isLicenseReturnModalOpen}
        handleClose={handleClose}
      />
    </LicensesContainer>
  )
}

export default Licenses
