import styled, { css } from 'styled-components/macro'
import { Header, Icon, Modal, Select, Input, Button, FileDrop } from '@scuf/common'
import { DataTable } from '@scuf/datatable'

export const DevicesContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`

export const TableContainer = styled.div`
  height: calc(100% - 3rem);
`

export const StyledDataTable = styled(DataTable)`
  min-height: 250px;
  & .cell-data-wrap {
      font-size: .875rem;
      @media (max-width: 768px) {
        font-size: .8rem;
        padding: 0 0.25rem !important;
      }
  }
  & .p-selection-column{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-header .select-all-checkbox{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-row {
     height:32px !important;
  }

  & .p-checkbox-box {
      max-width: 1rem !important;
      max-height: 1rem !important;
  }
 
  & .p-checkbox-icon {
      font-size:.5rem !important;
      top:-3px !important;
  }

  & .p-datatable-header {
      max-height: 3rem;
      background-color:#fff !important;
  }
  & .input-wrap {
      margin: .25rem 0 .25rem 0 ;
  }
  & .total-count {
      font-size: .875rem;
      font-weight:400;
  }
  & .p-datatable-scrollable-colgroup{
    display: none
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead > tr > th, .ui.forge-datatable-wrap .p-datatable .p-datatable-tfoot > tr > td{
    padding: 0.937rem 0.8rem 0.937rem 0.8rem !important;
  }
`

export const InputWrapper = styled.div`
  padding: 0 ;
  position:relative;
  text-overflow: ellipsis;
  &  .ui.single-input .input-box {
    height: 2.375rem ! important;
  }
  & .ui.scuf-dropdown-wrapper>.ui.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.multiple.selection.dropdown.fluid, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.dropdown.disabled.fluid {
    justify-content: space-between;
  }
  & .ui.scuf-dropdown-wrapper>.ui.selection.dropdown i.icon.chevron:before, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.multiple.selection.dropdown i.icon.chevron:before, html:not(.honeywell-dark-theme) .ui.scuf-dropdown-wrapper>.ui.dropdown.disabled i.icon.chevron:before {
    padding-right: .5rem;
  }
  & .ui.dropdown>.text {
    max-width: 16rem !important;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .ui.scuf-dropdown-wrapper.disabled .ui.disabled.dropdown i.icon {
    color: #f7f7f7 !important;
  }
  & .visible {
    max-height: 125px !important;
  }
`
export const StyledSelect = styled(Select)`
  width: auto !important;
  max-width: 100%;
  position: relative !important;
  margin-top: 0.75rem;
`
export const StyledDropdown = styled(Select)`
  width: auto !important;
  max-width: 30%;
  position: relative !important;
  margin-top: 0.75rem;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 512px) {
    width: 100%
  }
`
export const StyledModalContent = styled(Modal.Content)`
  //overflow: visible !important;
`

export const StyledInput = styled(Input)`
  margin-top: 0.75rem;
`
export const StyledModal = styled(Modal)`
overflow: scroll;
  width: 90% !important;
  max-width: 100%;
  background: ${props => props.theme.background};
  & .content {
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
`

export const Bottom = styled.div`
  margin-top:auto;
  padding: 0px 15px 15px 0px;
  & button {
    background-color: #303030 !important;
    color: #fff;
  }
  & button.disabled {
    background-color: #a0a0a0;
    border-color: #a0a0a0;
    color: #fff;
  }
 `
export const StyledButton = styled(Button)`
  margin:auto 0px 15px 0px !important;
  color: #fff;
        ${props =>
    props.disabled
      ? css`
      background-color: #a0a0a0;
      border-color: #a0a0a0;
            ` : css`
            background-color: #303030 !important;
            `};
    `

export const StyledTitle = styled.div`
font-weight: 700;
font-size: .875rem;
color: #303030;
flex-grow: 3;
`
export const ExportButton = styled.div`
text-align: right;
padding-right: 1.25rem;
`
export const FlexContainer = styled.div`
  display: flex;
  align-items: stretch;
  margin: 20px 0px 20px 0px;
`
export const FlexGrow1 = styled.div`
  flex-grow: 1;
  margin-top:auto;
`
export const FlexGrow3 = styled.div`
  flex-grow: 3;
  margin-top:auto;
  padding: 0px 15px 15px 0px;
  `

export const Content = styled.div`
  & .scrolling.content {
    overflow: unset !important;
  }
`
export const ActionText = styled.div`
  font-size: 14px;
  color: #303030;
  padding-left: 0.25rem;
`

export const AppFrameContainer = styled.section`
  height: 100vh;
  overflow: hidden;
`
export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledContentBody = styled.div`
  min-height: 450px;
  border: 1px solid #E0E0E0;
  padding: 1rem;
`
export const StyledTableContainer = styled.div`
  tbody {
      display: block;
      height: calc(50vh);
      overflow: auto;
  }
  thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;/* even columns width , fix width of table too*/
  }
  td{
      word-wrap:break-word;
  }
`
export const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background:${props => props.theme.background};
  color:'${props => props.theme.sidebarcolor}';
`
export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'} ;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
`
export const StyledIcon = styled(Icon)`
  color: #1B1D1F;
  font-size: 1.2rem !important;
  margin-top: 0.15rem;
  padding-left: 1.5rem;
`
export const StyledHeader = styled(Header)`

  & .profile-icon {
    border: solid 1px #f0f0f0;
    text-align: center;

  }
  & .page-header-menu.ui.menu .menu-btn-item {
    padding: 0.75rem 1.35rem !important;
  }
  & .honeywell-logo {
    margin-left: .8rem;
  }
  & .menu .transition .right .visible {
    position: absolute;
    z-index: 10;
  }
  & .page-header-menu.ui.menu .ui.dropdown.icon-menu{
    @media (max-width: 768px) {
      z-index: 10;
      position: absolute;
      transform: translateX(-40px);
    }
  }
`
export const StyledImage = styled.image`
  width: 50%;
  display: block;
  margin: auto;
`

export const StyledDiv = styled.div`
  display: block;
  text-align: center;
  margin-top: 20px;
  a{
    color: #303030 !important;
  }
`

export const StyledFileDrop = styled(FileDrop)`
&.ui.file-drop {
  .drop-zone {
    min-height: 8rem !important;
    ${props => props.height ? `height: ${props.height} !important;` : ''}
  }
}
.browse-files > .drop-zone-msg {
  display:none !important;
}
.drop-display-content > .upload-button {
  display:none !important;
}
`
