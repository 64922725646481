import Immutable, { ImmutableObject } from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { LicenseImportOnPremTypes } from './actions'

export type ImportOnPremStateTypes = {
  loading: boolean,
  showToast: number
}

export type LicenseImportOnPremStateTypes = ImportOnPremStateTypes & ImmutableObject<ImportOnPremStateTypes>

// export type TFunction = (state: StateTypes) => ImmutableObject<ImportOnPremStateTypes>

export interface FunctionType {
  (state: LicenseImportOnPremStateTypes) : ImmutableObject<ImportOnPremStateTypes>
}

export const INITIAL_STATE:ImmutableObject<ImportOnPremStateTypes> = Immutable({
  loading: false,
  showToast: 0,
  downloadTypesList: []
})

const loading:FunctionType = (state) => state.merge({ loading: true })
const requestStart:FunctionType = (state) => state.merge({ loading: true, showToast: 0 })
const requestComplete:FunctionType = (state) => state.merge({ loading: false })
const reset = () => INITIAL_STATE

export const licenseImportOnPremReducer = createReducer<LicenseImportOnPremStateTypes>(INITIAL_STATE, {
  [LicenseImportOnPremTypes.LOADING]: loading,
  [LicenseImportOnPremTypes.IMPORT_LICENSE]: requestStart,
  [LicenseImportOnPremTypes.IMPORT_LICENSE_COMPLETE]: requestComplete,
  [LicenseImportOnPremTypes.RESET]: reset
})
