export const isMobile = () => window.screen.width < 720

export const isMobilexs = window.screen.width < 321

export const LICENSES_TAB = 'Licenses'
export const LICENSES_BUNDLE_TAB = 'License Bundle'
export const SCHEDULE_TAB = 'Schedule'
export const HISTORY_TAB = 'History'
export const DEVICES_TAB = 'Devices'
export const OTHERS_TAB = 'Others'
export const SITE_NAME_STANDALONE = 'OIStandAloneLicenses'
export const SITE_ID_STANDALONE = '600956637ca6ea02b4d244ff'
export const OPINTEL_LICENSES_TAB = 'Opintel Licenses'
