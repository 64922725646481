import config from 'Config/AppConfig'

export class DevicesService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

    getDevicesList = () => this.api.get('/LicenseManagementSA/devices')
    deviceDiscovery = () => this.api.get('LicenseManagementSA/token')
    discoveredDevices = (sessionid) => this.api.get('LicenseManagementSA/devices/discover/' + sessionid)
    addDevices = (params) => this.api.post('/LicenseManagementSA/devices', params)
}
