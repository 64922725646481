import config from 'Config/AppConfig'
import { DEFAULT_PROFILE_ID } from '../../../Features/LicenseInstaller/licenseInstaller.constants'
const pagination = {
  pageNumber: 1,
  pageSize: 5000
}

export class LicensesService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

  getLicenses = (pageParams = pagination) => this.api.get(this.licenseController + '/licenses', pageParams)
  getLicenseBundles = () => this.api.get(this.licenseController + '/licenseBundles', { filter: { CompatibleDeviceType: 'mobilecomputer' } })
  licenseBundleExport = (params) => this.api.post(this.licenseController + '/bundle:export', params)
  getUserDetails = () => this.api.get('/UserManagement/self')
  getDevices = ({ siteId }) => {
    return this.api.get('/v2/devices', {
      filter: JSON.stringify({ siteId: { $in: [`${siteId}`] }, type: 'mobilecomputer', includeChildSites: 'true' }),
      pageNumber: 1,
      pageSize: 5000,
      profileId: DEFAULT_PROFILE_ID
    })
  }
}
