import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'
import { DataTable } from '@scuf/datatable'

export const SchedulesContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`

export const TableContainer = styled.div`
  height: calc(100% - 3rem);
`

export const StyledDataTable = styled(DataTable)`
  min-height: 500px;
  & .cell-data-wrap {
      font-size: .875rem;
      @media (max-width: 768px) {
        font-size: .8rem;
        padding: 0 0.25rem !important;
      }
  }
  & .p-selection-column{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-header .select-all-checkbox{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-row {
     height:32px !important;
  }

  & .p-checkbox-box {
      max-width: 1rem !important;
      max-height: 1rem !important;
  }
 
  & .p-checkbox-icon {
      font-size:.5rem !important;
      top:-3px !important;
  }

  & .p-datatable-header {
      max-height: 3rem;
      background-color:#fff !important;
  }
  & .input-wrap {
      margin: .25rem 0 .25rem 0 ;
  }
  & .total-count {
      font-size: .875rem;
      font-weight:400;
  }
  & .p-datatable-scrollable-colgroup{
    display: none
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead > tr > th, .ui.forge-datatable-wrap .p-datatable .p-datatable-tfoot > tr > td{
    padding: 0.937rem 0.8rem 0.937rem 0.8rem !important;
  }
`

export const StyledModal = styled(Modal)`
  background: ${props => props.theme.background};
  & .content {
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
`
export const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const StyledContentBody = styled.div`
    min-height: 350px;
`
export const StyledTableContainer = styled.div`
    tbody {
        display: block;
        height: calc(50vh);
        overflow: auto;
    }
    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
    }
    td{
        word-wrap:break-word;
    }
`
