import React from 'react'
import { Icon } from '@scuf/common'

import {
  StyledInstalLaterContainer,
  StyledItemContainer,
  OnboardTitle,
  OnboardSubtitle
} from './schedule.styles'

const InstallLaterMessage = () => {
  return (
    <StyledInstalLaterContainer>
      <StyledItemContainer>
        <Icon root='common' name='badge-info' size='xlarge' />
        <OnboardTitle>Click &ldquo;Next&rdquo; to confirm and save your installation.</OnboardTitle>
        <OnboardSubtitle>This will create a License Bundle with the previously selected devices and will be saved with the given name under License Bundle Tab, from which you can Schedule, Install or Edit this bundle.</OnboardSubtitle>
      </StyledItemContainer>
    </StyledInstalLaterContainer>
  )
}

export default InstallLaterMessage
