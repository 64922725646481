import { Icon } from '@scuf/common'
import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { getAlertColor } from './notification-bell.helper'
import moment from 'moment'
import config from 'Config/AppConfig'
import {
  NotificationsWrapper,
  NotificationLine,
  BellBadge,
  BellWrapper,
  NotificationColumn,
  NotificationRow,
  NotificationHeader,
  NotificationText,
  NotificationHeaderText,
  NotificationHeaderTitle,
  ZeroNotifications,
  HeaderNotificationsWrapper,
  StyledBadge,
  Timestamp
} from './notification-bell.styles'

import NotificationBellFooter from './components/footer'
import Polling from './components/polling/polling'

const AlertTime = ({ time }) => <p>{moment.utc(time).local().fromNow()}</p>

const NotificationAlert = memo(({ alert }) => {
  return (
    <NotificationLine>
      {getAlertColor(alert.type).map((key) =>
        <Icon key={key.color} color={key.color} name={key.icon} size='large' />
      )}
      <NotificationColumn>
        <NotificationHeader>
          <NotificationHeaderTitle>{alert.title}</NotificationHeaderTitle>
          <NotificationHeaderText><Timestamp> <AlertTime time={alert.time} /> </Timestamp></NotificationHeaderText>
        </NotificationHeader>
        <NotificationRow>
          <NotificationText>{alert.message}</NotificationText>
        </NotificationRow>
      </NotificationColumn>
    </NotificationLine>
  )
})

NotificationAlert.displayName = 'NotificationAlert'
NotificationAlert.propTypes = {
  alert: PropTypes.object,
  t: PropTypes.func
}

const BadgedIcon = memo(({ color, onClick }) => (
  <Icon color={color} name='notification' onClick={onClick} root='common' size='medium' />
))

BadgedIcon.displayName = 'BadgeIcon'
BadgedIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func
}
const NotificationBell = ({ alerts, unreadAlerts, getNotifications }) => {
  const [showNotifications, setShowNotifications] = useState(false)
  const notificationsWrapperRef = useRef()
  const modalRef = useRef()
  useEffect(() => {
    getNotifications()
    const notificationInterval = setInterval(
      () => getNotifications(),
      config.nonSinaps ? 15 * 60 * 1000 : 5 * 60 * 1000)
    return () => clearInterval(notificationInterval)
  }, [])

  const captureFilterClick = useCallback((e) => {
    if (!modalRef.current) {
      if (notificationsWrapperRef.current &&
        !notificationsWrapperRef.current.contains(e.target)) {
        setShowNotifications(false)
        document.removeEventListener('click', captureFilterClick)
      }
    } else if (notificationsWrapperRef.current &&
        !notificationsWrapperRef.current.contains(e.target) &&
        modalRef.current.contains(e.target)) {
      setShowNotifications(false)
      document.removeEventListener('click', captureFilterClick)
    }
  }, [])

  const handleShowNotifications = () => {
    document.addEventListener('click', captureFilterClick)
    setShowNotifications(true)
  }

  const clearCaptureFilterClick = useCallback(
    () => {
      setShowNotifications(false)
      document.removeEventListener('click', captureFilterClick)
    },
    [captureFilterClick]
  )

  return (
    <HeaderNotificationsWrapper className='notifications-wrapper'>
      <Polling>
        <BellWrapper color='black'>
          <BadgedIcon onClick={handleShowNotifications} />
          {unreadAlerts.length > 0 &&
            <BellBadge onClick={handleShowNotifications}>
              <StyledBadge color='red'>+{unreadAlerts.length}</StyledBadge>
            </BellBadge>}
        </BellWrapper>
        {showNotifications &&
          <NotificationsWrapper ref={(node) => { notificationsWrapperRef.current = node }}>
            {unreadAlerts
              .slice(0, 5)
              .map(alert => (
                <NotificationAlert alert={alert} key={alert.id} />
              ))}
            {
              unreadAlerts.length === 0
                ? <ZeroNotifications>You do not have any new Notifications</ZeroNotifications> : null
            }
            {
              alerts.length > 0 && <NotificationBellFooter clearCaptureFilterClick={clearCaptureFilterClick} modalRef={modalRef} />
            }

          </NotificationsWrapper>}
      </Polling>
    </HeaderNotificationsWrapper>
  )
}

export default NotificationBell
