import config from 'Config/AppConfig'
export class LicenseImportService {
  constructor (api) {
    this.api = api
    this.licenseController = config.nonSinaps?'/LicenseManagementSA':'/LicenseManagement'
  }

  getSites = () => this.api.get('/groupmgmt/OrgUnits/administrable', {
    pageNumber: 1, pageSize: 1
  })

  importLicense = (params) => this.api.post(this.licenseController + '/licenses', params)
}
