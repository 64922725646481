import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { OpintelLicensesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  opintelLicensesList: []
})

const loading = state => state.merge({ loading: true })
const updateOpintelLicenses = (state, { opintelLicenses }) => state.merge({ opintelLicensesList: opintelLicenses, loading: false })
const updateOpintelLicensesFailure = state => state.merge({ opintelLicensesList: [], loading: false })
const reset = () => INITIAL_STATE

export const opintelLicensesReducer = createReducer(INITIAL_STATE, {
  [OpintelLicensesTypes.GET_OPINTEL_LICENSES]: loading,
  [OpintelLicensesTypes.LOADING]: loading,
  [OpintelLicensesTypes.UPDATE_OPINTEL_LICENSES]: updateOpintelLicenses,
  [OpintelLicensesTypes.UPDATE_OPINTEL_LICENSES_FAILURE]: updateOpintelLicensesFailure,
  [OpintelLicensesTypes.RESET]: reset
})
