import React, { useState, useEffect } from 'react'
import { Stepper, Button } from '@scuf/common'

import Loader from '../../../../Components/Loader'
import { useTabsList } from './useTabsList'
import {
  StyledModal,
  StyledContentContainer,
  StyledContentBody,
  StyledDiv
} from '../../licenseInstaller.styles'
import {
  CONFIRMATION_TAB,
  SCHEDULE_TAB,
  SOFTWARE_TAB,
  DEVICES_TAB,
  SCHEDULE,
  INSTALL_NOW,
  LICENCE_BUNDLE_DEVICE_LIMIT,
  SITE_FILTER_TYPE,
  ORG_FILTER_TYPE
} from '../../licenseInstaller.constants'
import config from 'Config/AppConfig'
import moment from 'moment'

const LicenseInstallerModal = ({
  selectedSoftwares,
  bundleName,
  selectedDevicesList,
  scheduleType,
  scheduleTimezone,
  scheduleFromDate,
  scheduleFromTime,
  scheduleToDate,
  scheduleToTime,
  scheduleFromDatetime,
  scheduleToDatetime,
  openModal,
  handelClose,
  saveBundle,
  scheduleBundle,
  resetLicenseInstaller,
  loading,
  showToast,
  handleBack,
  activeTab,
  setInstallNowScheduleType,
  deviceFilterType,
  selectedSite,
  mode,
  selectedDevicesCount,
  flags
}) => {
  const [tabIndex, setTabIndex] = useState(activeTab);
  useEffect(() => {
    if(activeTab === 4) {
      setInstallNowScheduleType()
      setTabIndex(3)
    } else {
      setTabIndex(activeTab)
    }
  }, [activeTab]);

  const [activeInstallerSection, setActiveInstallerSection, tabsList, tabBody] = useTabsList({
    selectedSoftwares,
    selectedDevicesList,
    bundleName,
    scheduleType,
    scheduleTimezone,
    scheduleFromDate,
    scheduleFromTime,
    scheduleToDate,
    scheduleToTime,
    scheduleFromDatetime,
    scheduleToDatetime,
    tabIndex: activeTab,
    deviceFilterType,
    selectedSite,
    mode,
    selectedDevicesCount
  })
  useEffect(() => {
    if(activeTab === CONFIRMATION_TAB) {
      setInstallNowScheduleType()
      setActiveInstallerSection(SCHEDULE_TAB)
    } else {
      setActiveInstallerSection(activeTab)
    }
  }, [activeTab]);

  const tabsCount = tabsList.length
  return (
    <StyledModal open={openModal} closeIcon={true} onClose={handleCloseAction} size='large' closeOnDimmerClick={false}>
      <StyledModal.Header>
        {loading && <Loader loading={loading} />}
        <StyledDiv>
          License Installer
        </StyledDiv>
      </StyledModal.Header>
      <StyledModal.Content>
        <StyledContentContainer>
          <Stepper fluid={true} ordered={true}>
            {tabsList.map(item => {
              return (
                <Stepper.Item
                  key={item.title}
                  title={item.title}
                  active={item.active()}
                  completed={item.completed()}
                  onClick={item.handleClick}
                  disabled={item.disabled && item.disabled()}
                />
              )
            })}
          </Stepper>
          <StyledContentBody>
            {tabBody[activeInstallerSection]}
          </StyledContentBody>
        </StyledContentContainer>
      </StyledModal.Content>
      <StyledModal.Footer>
        <Button
          type='secondary'
          size='medium'
          content={activeInstallerSection === SOFTWARE_TAB ? 'Close' : 'Back'}
          onClick={handelBackAndClose}
          disabled={checkValidTabIndex()}
        />
        <Button
          type='primary'
          size='medium'
          content={activeInstallerSection === tabsCount ? (showToast === 1 || showToast === -1 ? 'Close' : 'Save') : 'Next'}
          onClick={activeInstallerSection === tabsCount ? (showToast === 1 || showToast === -1 ? handleCloseAction : handelSaveAndNext) : handelSaveAndNext}
          disabled={checkDevicesValidation() || (tabIndex === SCHEDULE_TAB || tabIndex === CONFIRMATION_TAB) && ((checkScheduleValidation() && !config.nonSinaps) || (checkImmediateValidation() && !config.nonSinaps))}
        />
      </StyledModal.Footer>
    </StyledModal>
  )

  function checkDevicesValidation() {
    return (activeInstallerSection === DEVICES_TAB && !config.nonSinaps && (!bundleName || ((deviceFilterType === (ORG_FILTER_TYPE || SITE_FILTER_TYPE)) && selectedDevicesList.length === 0) || deviceFilterType === 0 || !selectedSite))
  }

  function checkScheduleValidation() {
    const fromDateTime = moment(scheduleFromDatetime)
    const toDateTime = moment(scheduleToDatetime)
    const todayDate = new Date()
    const currentTm = moment.tz(moment(), scheduleTimezone).format('HH:mm')
    const currentDateVal = moment(todayDate)
    const hours = currentTm.split(':')[0]
    const minutes = currentTm.split(':')[1]
    currentDateVal.set('hour', 0).set('minute', 0).set('second', 0)
    currentDateVal.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
    return (activeInstallerSection === SCHEDULE_TAB && scheduleType === SCHEDULE &&
      (!scheduleTimezone ||
        !scheduleFromDate || !scheduleFromTime ||
        !scheduleToDate || !scheduleToTime ||
        currentDateVal.diff(fromDateTime) > 0 ||
        fromDateTime.diff(toDateTime) > 0)) 
        || fromDateTime.diff(toDateTime) == isNaN
        || scheduleToDate == 'Invalid date';
  }

  function checkImmediateValidation() {
    const fromDateTime = moment(scheduleFromDatetime)
    const toDateTime = moment(scheduleToDatetime)
    return (activeInstallerSection === SCHEDULE_TAB && scheduleType === INSTALL_NOW &&
      (!scheduleTimezone || !scheduleToDate || !scheduleToTime ||
        fromDateTime.diff(toDateTime) > 0))
  }

  function handelBackAndClose() {
    if ((activeTab !== SCHEDULE_TAB && activeTab !== CONFIRMATION_TAB) || ((activeTab === SCHEDULE_TAB || activeTab === CONFIRMATION_TAB)
      && (activeInstallerSection === CONFIRMATION_TAB))) {
      handleBack()
      if (activeInstallerSection === SOFTWARE_TAB || showToast === '1') {
        handleCloseAction()
      } else setActiveInstallerSection(activeInstallerSection - 1)
    }
  }

  function handelSaveAndNext() {
    if (activeInstallerSection === tabsCount) {
      if (tabIndex === SOFTWARE_TAB || tabIndex === DEVICES_TAB) {
        flags.licenseBundlesV2 === true ? saveBundle(true) : saveBundle(false)
      } else {
        scheduleBundle()
      }
    } else {
      setActiveInstallerSection(activeInstallerSection + 1)
    }
  }

  function handleCloseAction() {
    setTabIndex(0)
    resetLicenseInstaller()
    handelClose()
  }

  function checkValidTabIndex() {
    return ((activeTab === SCHEDULE_TAB || activeTab === CONFIRMATION_TAB) && activeInstallerSection === SCHEDULE_TAB);
  }
}

export default React.memo(LicenseInstallerModal)
