import * as hwColors from './honeywell-colors'
import * as dimensions from './dimensions'
import * as mediaTypes from './media-types'
import { compact, ThemeTypography } from './typography'
import { light, dark, ThemeColorScheme } from './color-schemes'

export interface AppTheme {
  hwColors: typeof hwColors;
  dimensions: typeof dimensions;
  mediaTypes: typeof mediaTypes;
  colors: ThemeColorScheme;
  typography: ThemeTypography;
}

export const lightCompactTheme = {
  hwColors,
  colors: light,
  dimensions,
  mediaTypes,
  typography: compact,
  background: '#f7f7f7',
  padding: '20px'
}

export const darkCompactTheme = {
  hwColors,
  colors: dark,
  dimensions,
  mediaTypes,
  typography: compact,
  background: '#202020',
  padding: '20px'
}

// export type MyTheme = typeof lightCompactTheme
