
import { connect } from 'react-redux'
import mapStateToProps from './footer.select'
import Footer from './footer'
import { NotificationsActions } from '../../store'
import { pipe } from 'ramda'

export const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(NotificationsActions.getNotifications()),
  statusUpdateNotifications: (notificationIds) => dispatch(NotificationsActions.statusUpdateNotifications(notificationIds))
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Footer)
