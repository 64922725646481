import { createStructuredSelector, createSelector } from 'reselect'
import moment from 'moment-timezone'

const licenseInstaller = ({ licenseInstaller }) => licenseInstaller

const scheduleDetails = createSelector(
  [licenseInstaller],
  licenseInstaller => ({
    scheduleType: licenseInstaller.getIn(['scheduleType'], 3),
    scheduleTimezone: licenseInstaller.getIn(['scheduleTimezone'], null),
    scheduleFromDate: licenseInstaller.getIn(['scheduleFromDate'], null),
    scheduleFromTime: licenseInstaller.getIn(['scheduleFromTime'], null),
    scheduleToDate: licenseInstaller.getIn(['scheduleToDate'], null),
    scheduleToTime: licenseInstaller.getIn(['scheduleToTime'], null),
    scheduleFromDatetime: licenseInstaller.getIn(['scheduleFromDatetime'], null)
      ? moment.tz(licenseInstaller.getIn(['scheduleFromDatetime'], null), licenseInstaller.getIn(['scheduleTimezone'], null))
      : null,
    scheduleToDatetime: licenseInstaller.getIn(['scheduleToDatetime'], null)
      ? moment.tz(licenseInstaller.getIn(['scheduleToDatetime'], null), licenseInstaller.getIn(['scheduleTimezone'], null))
      : null
  })
)

export default createStructuredSelector({
  scheduleDetails
})
