export const INSTALL_NOW = 1
export const INSTALL_LATER = 2
export const SCHEDULE = 3
export const REQUEST_PRIORITY_IMMEDIATE = 'Immediate'
export const REQUEST_PRIORITY_OPTIONAL = 'Optional'
export const ASSET_NAME = 'License Configuration: '
export const ASSET_TYPE = 'DEVCONFIG-text/xml-hon-license-config'
export const DEVICE_TYPE_MOBILE_COMPUTER = 'mobilecomputer'
export const SCHEDULED_VERSION = '1.1'
export const DEVICE_VERSION = '1.0'
export const ASSET_CATEGORY = 'Configuration file'
export const SOFTWARE_TAB = 1
export const DEVICES_TAB = 2
export const SCHEDULE_TAB = 3
export const CONFIRMATION_TAB = 4
export const SITE_FILTER_TYPE = 1
export const SITE_FILTERED_DEVICE_COUNT = 500
export const ORG_FILTER_TYPE = 2
export const ORG_FILTERED_DEVICE_COUNT = 500
export const DEFAULT_PROFILE_ID = '684D492B-CE98-43CF-AEEF-F333A84440BF'
export const LICENCE_BUNDLE_DEVICE_LIMIT = 100

