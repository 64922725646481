import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Schedule from './schedule'
import mapStateToProps from './schedule.selector'
import { LicenseInstallerActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  updateScheduleType: (type) => dispatch(LicenseInstallerActions.updateScheduleType(type)),
  updateScheduleZoneAndDatetime: (timezone, datetime, scheduleFromDate, scheduleFromTime) => dispatch(LicenseInstallerActions.updateScheduleZoneAndDatetime(timezone, datetime, scheduleFromDate, scheduleFromTime)),
  updateScheduleZoneAndToDatetime: (timezone, datetime, scheduleToDate, scheduleToTime) => dispatch(LicenseInstallerActions.updateScheduleZoneAndToDatetime(timezone, datetime, scheduleToDate, scheduleToTime))
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Schedule)
