import { createStructuredSelector } from 'reselect'

const devices = ({ devices }) => devices.getIn(['devicesList'], [])
const deviceTypes = ({ devices }) => devices.getIn(['deviceTypesList'], [])
const deviceDiscoveryResponse = ({ devices }) => devices.getIn(['deviceDiscoveryResponse'], [])
const discoveredDeviceList = ({ devices }) => devices.getIn(['discoveredDeviceList'], [])
const loading = ({ devices }) => devices.getIn(['loading'], false)

export default createStructuredSelector({
  loading,
  devices,
  deviceTypes,
  deviceDiscoveryResponse,
  discoveredDeviceList
})
