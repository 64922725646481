import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import { OpintelLicensesApi } from 'Services'
import { SessionExpiryActions } from 'Features/SessionExpiry/store'
import { OpintelLicensesTypes, OpintelLicensesActions } from './actions'

export function * getOpintelLicenses (api) {
  try {
    const response = yield call(api.getOpintelLicenses)
    if (response.ok) {
      yield put(OpintelLicensesActions.updateOpintelLicenses(response.data.licenses))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(OpintelLicensesActions.updateOpintelLicensesFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(OpintelLicensesActions.updateOpintelLicensesFailure())
    ])
  }
}

export function * opintelLicensesSagas () {
  yield all([
    takeLatest(OpintelLicensesTypes.GET_OPINTEL_LICENSES, getOpintelLicenses, OpintelLicensesApi)
  ])
}
