import React, { useEffect } from 'react'
import {
  OpintelLicensesContainer,
  TableContainer,
  StyledDataTable
} from '../../opintelLicenses.styles'

function Render ({ value }) {
  return <p>{value}</p>
}

const OpintelLicenses = ({
  loading,
  opintelLicensesList,
  getOpintelLicenses,
  reset
}) => {
  useEffect(() => {
    getOpintelLicenses()
    return () => reset()
  }, [getOpintelLicenses, reset])

  const columns = [
    { field: 'featureName', header: 'Subscription Name', initialWidth: '16%', render: Render },
    { field: 'activationId', header: 'Activation ID', initialWidth: '20%', render: Render },    
    { field: 'availableCount', header: 'Available', initialWidth: '12%', render: Render },
    { field: 'assignedCount', header: 'Assigned', initialWidth: '11%', render: Render },
    { field: 'importedCount', header: 'Total', initialWidth: '10%', render: Render },    
    { field: 'expiresOn', header: 'Maintenance Expiry', initialWidth: '17%', render: Render },
    { field: 'expirationDate', header: 'Expiration Date', initialWidth: '14%', render: Render }
  ]

  return (
    <OpintelLicensesContainer>
      <TableContainer>
        <StyledDataTable
          loading={loading}
          data={opintelLicensesList}
          search={true}
          searchPlaceholder='Search for software'
          scrollHeight='58vh'
          scrollable={true}
          resizableColumns={true}
          columnResizeMode='expand'
          reorderableColumns={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
        </StyledDataTable>
      </TableContainer>
    </OpintelLicensesContainer>
  )
}
export default OpintelLicenses
