
export const getAlertColor = type => {
  const iconProps = []
  const element = {}
  switch (type) {
    case 'critical':
      element.color = '#BE271C'
      element.icon = 'badge-warning'
      iconProps.push(element)
      break
    case 'info':
      element.color = '#1792E5'
      element.icon = 'badge-info'
      iconProps.push(element)
      break
    default:
      element.color = '#808080'
      iconProps.push(element)

      break
  }
  return iconProps
}
