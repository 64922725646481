import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Licenses from './licenses'
import mapStateToProps from './licenses.selector'
import { LicensesActions } from '../../store'
import { LicenseInstallerActions } from '../../../LicenseInstaller'

const mapDispatchToProps = dispatch => ({
  getLicenses: () => dispatch(LicensesActions.getLicenses()),
  updateSelectedSoftwares: (selectedSoftwares) => dispatch(LicenseInstallerActions.updateSelectedSoftwares(selectedSoftwares)),
  reset: () => dispatch(LicensesActions.reset())
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Licenses)
