import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'

import {
  HistorysTypes,
  HistorysActions
} from './actions'

import { HistorysApi } from '../../../Services'
import {SessionExpiryActions} from '../../SessionExpiry/store'
export function* getHistorys(api) {
  try {
    const response = yield call(api.getHistorys)
    if (response.ok) {
      yield put(HistorysActions.updateHistorys(formatHistorysData(response.data)))
    } else {
      if(response.status == 401)
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      console.log("Error getting History..")
      yield all([
        put(HistorysActions.updateHistorysFailure())
      ])
    }
  } catch (_) {
    console.log("Error getting History")
    yield all([
      put(HistorysActions.updateHistorysFailure())
    ])
  }
}

function formatHistorysData(data) {
  let response = []
  var count = 0
  for (var i = 0; i < data.length; i++) {
    {
      for (var j = 0; j < data[i].softwareDetail.length; j++) {
        response[count++] = {
          'id': data[i].masterGuid + '.' + data[i].softwareDetail[j].deviceSerialNumber,
          'displayName': data[i].softwareDetail[j].displayName.replace("License Configuration: ", ""),
          'deviceSerialNumber': data[i].softwareDetail[j].deviceSerialNumber,
          'status': data[i].softwareDetail[j].status,
          'scheduledVersion': data[i].softwareDetail[j].scheduledVersion,
          'scheduledDate': data[i].softwareDetail[j].scheduledDate,
          'scheduledTimeZone': data[i].softwareDetail[j].scheduledTimeZone,
          'version': data[i].softwareDetail[j].scheduledVersion,
        }
      }
    }
  }
  return response
}

export function* historysSagas() {
  yield all([
    takeLatest(HistorysTypes.GET_HISTORYS, getHistorys, HistorysApi)
  ])
}
