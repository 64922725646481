import styled from 'styled-components/macro'
import { Input } from '@scuf/common'
import { DataTable } from '@scuf/datatable'

export const StyledDeviceContainer = styled.div`
    display:flex;
    flex-direction: column;  
    width: 100%;
`
export const StyledLabel = styled.label`
    font-size: .875rem;
    font-weight: 900;
`
export const StyledInput = styled(Input)`
  width:60% ;
  margin-bottom: 1rem;
  & .below-text {
    min-height: 0 !important;
  }
  & input {
    width: 100% !important;
  }
  & div {
    width: 100% !important;
  }
`
export const ActionBarContainer = styled.div`
    display:flex;
    background: transparent;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
`
export const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'} ;
`
export const StyledParagraph = styled.div`
    font-weight: 500 !important;    
    margin-bottom: 0.812rem;
    text-align: justify !important;
    color: #707070 !important;
`
export const StyledDataTable = styled(DataTable)`
  margin-bottom: 1rem;
  & .cell-data-wrap {
      font-size: .875rem;
      @media (max-width: 768px) {
        font-size: .8rem;
        padding: 0 0.25rem !important;
      }
  }
  & .p-datatable-row {
     height:32px !important;
  }
  & .p-checkbox{
    position: unset !important;
  }
  & .p-checkbox-box {
      max-width: 1rem !important;
      max-height: 1rem !important;
  }

  & .p-checkbox-icon {
      font-size:.5rem !important;
      top:-3px !important;
  }

  & .p-datatable-header {
      max-height: 3rem;
      background-color:#fff !important;
  }
  & .input-wrap {
      margin: .25rem 0 .25rem 0 ;
  }
  & .total-count {
      font-size: .875rem;
      font-weight:400;
  }
  & .p-selection-column{
    line-height: 0 !important;    
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-scrollable-colgroup{
    display: none
  }
  & .p-datatable-scrollable-body{
    min-height: 34px !important;
  }
  & .p-datatable-header .select-all-checkbox{
    display: none !important;
  }
  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead > tr > th, .ui.forge-datatable-wrap .p-datatable .p-datatable-tfoot > tr > td{
    padding: 0.937rem 0.8rem 0.937rem 0.8rem !important;
  }
  & .datatable-wrap .p-datatable tbody.p-datatable-tbody>tr.p-highlight {
    background-color: #C0C0C0 !important;
   }
   & .search-output .input-box{
    width: 15rem !important;
   }
`

export const ErrorContent = styled.div`
    display: flex;
    -webkit-box-align: center;
    place-items: center;
    flex-direction: column;
    place-self: center;
`

export const ErrorTitle = styled.div`
  font-size: 2em;
  font-weight: 500;
  color: #303030;
  margin: 1rem;
`

export const ErrorInfo = styled.div`
  font-size: 1.25em;
  font-weight: 500;
  color: #606060;
  width: 65%;
  text-align: center;
`

export const SiteSelectionContainer = styled.div`
   margin-top: 1em;
   margin-bottom: 1em;
`