import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { LicenseImportTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  sitesList: [],
  showToast: 0,
  message: '',
  currentOrganization: {},
  licenseImportResult: null
})

const loading = state => state.merge({ loading: true })
const requestStart = state => state.merge({ loading: true, showToast: 0 })
const requestComplete = state => state.merge({ loading: false })
const updateSitesList = (state, { sites }) => state.merge({ sitesList: sites, loading: false })
const updateSitesFailure = state => state.merge({ sitesList: [], loading: false })
const updateLicenseImportResult = (state, { licenseImportResult }) => state.merge({ licenseImportResult, loading: false })
const reset = () => INITIAL_STATE
const updateOrganization = (state, { organization }) => state.merge({ currentOrganization: organization })

export const licenseImportReducer = createReducer(INITIAL_STATE, {
  [LicenseImportTypes.IMPORT_LICENSE]: requestStart,
  [LicenseImportTypes.IMPORT_LICENSE_COMPLETE]: requestComplete,
  [LicenseImportTypes.GET_SITES]: loading,
  [LicenseImportTypes.UPDATE_SITES]: updateSitesList,
  [LicenseImportTypes.UPDATE_SITES_FAILURE]: updateSitesFailure,
  [LicenseImportTypes.RESET]: reset,
  [LicenseImportTypes.UPDATE_ORGANIZATION]: updateOrganization,
  [LicenseImportTypes.LICENSE_IMPORT_RESULT]: updateLicenseImportResult
})
