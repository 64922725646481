import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Devices from './devices'
import mapStateToPrps from './devices.selector'
import { LicenseInstallerActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getDevices: (siteId, hasChildSites, filterType, pageNumber, currentData, searchString) => dispatch(LicenseInstallerActions.getDevices(siteId, hasChildSites, filterType, pageNumber, currentData, searchString)),
  updateBundleName: (bundleName) => dispatch(LicenseInstallerActions.updateBundleName(bundleName)),
  updateSelectedDevices: (selectedDevices) => dispatch(LicenseInstallerActions.updateSelectedDevices(selectedDevices)),
  getSites: (siteId) => dispatch(LicenseInstallerActions.getSites(siteId))
})

export default pipe(
  connect(mapStateToPrps, mapDispatchToProps)
)(Devices)
