import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  loading: null,
  getSites: null,
  updateSites: ['sites'],
  importLicense: ['activationId', 'licenseNo', 'siteId', 'siteName'],
  importLicenseComplete: null,
  updateSitesFailure: null,
  updateOrganization: ['organization'],
  licenseImportResult: ['licenseImportResult']
}, {
  prefix: '@LICENSEIMPORT/'
})

export const LicenseImportTypes = Types
export const LicenseImportActions = Creators
