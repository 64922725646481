import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loading: null,
  getNotifications: null,
  updateNotifications: ['notifications'],
  updateNotificationFailure: null,
  statusUpdateNotifications: ['notificationIds'],
  statusUpdateNotificationsComplete: null
}, {
  prefix: '@NOTIFICATIONS/'
})

export const NotificationsTypes = Types
export const NotificationsActions = Creators
