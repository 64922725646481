import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { DevicesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  devicesList: [],
  deviceTypesList: [],
  deviceDiscoveryResponse: [],
  discoveredDeviceList: []
})

const loading = state => state.merge({ loading: true })
const requested = state => state.merge({ loading: true, showToast: 0 })
const requestCompleted = state => { return state.merge({ loading: false }) }
const updateDevicesList = (state, { devices }) => state.merge({ devicesList: devices, loading: false })
const updateDeviceDiscovery = (state, { deviceDiscoveryResponse }) => state.merge({ deviceDiscoveryResponse, loading: false })
const updateDiscoveredDevices = (state, { discoveredDeviceList }) => state.merge({ discoveredDeviceList, loading: false })
const updateDeviceTypesList = (state, { deviceTypes }) => state.merge({ deviceTypesList: deviceTypes, loading: false })
const updateDevicesFailure = state => state.merge({ devicesList: [], loading: false })
const updateDeviceDiscoveryFailure = state => state.merge({ deviceDiscoveryResponse: null, loading: false })
const updateDiscoveredDevicesFailure = state => state.merge({ discoveredDeviceList: null, loading: false })
const reset = () => INITIAL_STATE

export const devicesReducer = createReducer(INITIAL_STATE, {
  [DevicesTypes.GET_DEVICES_LIST]: loading,
  [DevicesTypes.DEVICE_DISCOVERY]: loading,
  [DevicesTypes.LOADING]: loading,
  [DevicesTypes.ADD_DEVICES]: requested,
  [DevicesTypes.ADD_DEVICES_COMPLETE]: requestCompleted,
  [DevicesTypes.GET_DEVICE_TYPES]: loading,
  [DevicesTypes.UPDATE_DEVICE_TYPES]: updateDeviceTypesList,
  [DevicesTypes.UPDATE_DEVICES]: updateDevicesList,
  [DevicesTypes.UPDATE_DEVICE_DISCOVERY]: updateDeviceDiscovery,
  [DevicesTypes.DISCOVERED_DEVICES]: loading,
  [DevicesTypes.UPDATE_DISCOVERED_DEVICES]: updateDiscoveredDevices,
  [DevicesTypes.UPDATE_DEVICES_FAILURE]: updateDevicesFailure,
  [DevicesTypes.UPDATE_DEVICE_DISCOVERY_FAILURE]: updateDeviceDiscoveryFailure,
  [DevicesTypes.UPDATE_DISCOVERED_DEVICES_FAILURE]: updateDiscoveredDevicesFailure,
  [DevicesTypes.RESET]: reset
})
