import { pipe } from 'ramda'
import { connect } from 'react-redux'
import LicenseBundles from './licenseBundles'
import mapStateToProps from './licenseBundles.selector'
import { LicensesActions } from '../../store'
import { LicenseInstallerActions } from '../../../LicenseInstaller'

const mapDispatchToProps = dispatch => ({
  getLicenseBundles: () => dispatch(LicensesActions.getLicenseBundles()),
  updateLicenseInstaller: (selectedBundle, activeTabIndex) => dispatch(LicenseInstallerActions.updateLicenseInstaller(selectedBundle, activeTabIndex)),  
  licenseBundleExport: (licenseBundle) => dispatch(LicensesActions.licenseBundleExport(licenseBundle)),
  updateExportInProgress: () => dispatch(LicensesActions.updateExportInProgress()),
  getUserDetails: () => dispatch(LicensesActions.getUserDetails()),
  getDevices: (siteId) => dispatch(LicensesActions.getDevices(siteId))
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(LicenseBundles)
