import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { HistorysTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  historysList: []
})

const loading = state => state.merge({ loading: true })
const updateHistorysList = (state, { historys }) => state.merge({ historysList: historys, loading: false })
const updateHistorysFailure = state => state.merge({ historysList: [], loading: false })

export const historysReducer = createReducer(INITIAL_STATE, {
  [HistorysTypes.GET_HISTORYS]: loading,
  [HistorysTypes.LOADING]: loading,
  [HistorysTypes.UPDATE_HISTORYS]: updateHistorysList,
  [HistorysTypes.UPDATE_HISTORYS_FAILURE]: updateHistorysFailure
})
