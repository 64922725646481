import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import {
  OthersContainer,
  TableContainer,
  StyledDataTable
} from '../../others.styles'
import Loader from 'Components/Loader'

function Render ({ value }) {
  return <p>{value}</p>
}

function _dateFormat ({ value }) {
  const finalValue = value ? moment(value).locale(window.navigator.language).format('MM-DD-YYYY HH:mm:ss') : 'Not Available'
  return <p>{finalValue}</p>
}

const Others = ({
  loading,
  othersList,
  exportInProgress,
  getOthersList,
  othersBundleExport,
  updateExportInProgress
}) => {
  const [selectedRows, setSelectedRows] = useState([])
  useEffect(() => {
    getOthersList()
  }, [getOthersList])

  const handleExportback = useCallback(
    () => {
      const selectedData = [...selectedRows]
      const bundleData = {
        bundleId: selectedData[0].id,
        deviceType: selectedData[0].compatibleDeviceType,
        siteId: selectedData[0].siteId
      }
      updateExportInProgress()
      othersBundleExport(bundleData)
      setSelectedRows([])
    },
    [selectedRows, updateExportInProgress, othersBundleExport]
  )

  const { Item } = StyledDataTable.ActionBar
  const columns = [
    { field: 'bundleName', header: 'Host Id', initialWidth: '15%', render: Render },
    { field: 'activationId', header: 'Activation Id', initialWidth: '30%', render: Render },
    { field: 'featureName', header: 'Software Name', initialWidth: '20%', render: Render },
    { field: 'maintenanceExpiration', header: 'Maintenance Expiration', initialWidth: '15%', render: Render },
    { field: 'expirationDate', header: 'Expiration Date', initialWidth: '20%', render: Render },
    { field: 'quantity', header: 'Device Count', initialWidth: '10%', render: Render },
    { field: 'createdOn', header: 'Created On', initialWidth: '20%', render: Render },
    { field: 'createdBy', header: 'Created By', initialWidth: '20%', render: Render }
  ]

  return (
    <OthersContainer>
      {exportInProgress && <Loader loading={exportInProgress} />}
      <TableContainer>
        <StyledDataTable
          loading={loading}
          data={othersList}
          search={true}
          selection={selectedRows}
          selectionMode='single'
          onSelectionChange={(rows) => setSelectedRows(rows)}
          searchPlaceholder='Search for software'
          scrollHeight='58vh'
          scrollable={true}
          resizableColumns={true}
          columnResizeMode='expand'
          reorderableColumns={true}
        >
          {columns
            .map(key =>
              <StyledDataTable.Column
                key={key.field}
                field={key.field}
                initialWidth={key.initialWidth}
                renderer={key.render}
                header={key.header}
                sortable={true}
              />)}
          <StyledDataTable.ActionBar clearSelectionOnClose={true}>
            <Item
              icon='file-download'
              iconSize='small'
              content='Export'
              onClick={() => handleExportback()}
            />
          </StyledDataTable.ActionBar>
        </StyledDataTable>
      </TableContainer>
    </OthersContainer>
  )
}

export default Others
