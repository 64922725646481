import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { SuccessToast, ErrorToast } from '../../../Themes/ScufStyledComponents'
import config from 'Config/AppConfig'
import {
  DevicesTypes,
  DevicesActions
} from './actions'

import { DevicesApi } from '../../../Services'
import { LicenseInstallerActions } from '../../LicenseInstaller/store/actions'
import { SessionExpiryActions } from '../../SessionExpiry/store'

export function * getDevicesList (api) {
  try {
    const response = yield call(api.getDevicesList)
    if (response.ok) {
      yield put(DevicesActions.updateDevices(formatDevicesData(response.data.data)))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(DevicesActions.updateDevicesFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(DevicesActions.updateDevicesFailure())
    ])
  }
}

export function * deviceDiscovery (api) {
  try {
    const response = yield call(api.deviceDiscovery)
    if (response.ok) {
      if (response.status === 200) {
        if (response.data) {
          yield put(DevicesActions.updateDeviceDiscovery(response.data))
        }
      } else {
        if (response.status === 401) {
          yield all([
            put(SessionExpiryActions.sessionExpiry())
          ])
        }
        yield all([
          put(DevicesActions.updateDeviceDiscoveryFailure())
        ])
      }
    }
  } catch (_) {
    yield all([
      put(DevicesActions.updateDeviceDiscoveryFailure())
    ])
  }
}

export function * discoveredDevices (api, { sessionId }) {
  try {
    const response = yield call(api.discoveredDevices, sessionId)
    if (response.ok) {
      if (response.status === 200) {
        yield put(DevicesActions.updateDiscoveredDevices(response.data.deviceDetails))
      } else {
        if (response.status === 401) {
          yield all([
            put(SessionExpiryActions.sessionExpiry())
          ])
        }
        yield all([
          put(DevicesActions.updateDiscoveredDevicesFailure())
        ])
      }
    }
  } catch (_) {
    yield all([
      put(DevicesActions.updateDiscoveredDevicesFailure())
    ])
  }
}

function formatDevicesData (data) {
  const response = []
  let count = 0
  for (let i = 0; i < data.length; i++) {
    {
      response[count++] = {
        type: data[i].type,
        alias: data[i].name,
        model: data[i].model,
        serialNumber: data[i].serialNumber,
        addedOn: data[i].addedOn
      }
    }
  }
  return response
}

export function * addDevices (api, { devices }) {
  try {
    const payLoad = {
      Devices: devices
    }
    if(config.persistAddDevices)
    {
      const response = yield call(api.addDevices, payLoad)
      if (response.ok) {
        if (response.status === 200 || response.status === 201) {
          toast(<SuccessToast message='Device Added Successfully' />)
          yield all([
            put(DevicesActions.addDevicesComplete())
          ])
        } else {
          toast(<ErrorToast message='Error while adding devices' />)
          yield all([
            put(DevicesActions.addDevicesComplete())
          ])
        }
      } else {
        if (response.status === 401) {
          yield all([
            put(SessionExpiryActions.sessionExpiry())
          ])
        } else { toast(<ErrorToast message='Devices Added Failed' />) }
        yield all([
          put(DevicesActions.addDevicesComplete())
        ])
      }
    }
    else
    {
      let deviceList = deviceDataFormat(devices)
      yield all([
        put(LicenseInstallerActions.updateDevices(deviceList)),
        put(LicenseInstallerActions.updateSelectedDevices(deviceList))
      ])    
    }
  } catch (_) {
    toast(<ErrorToast message='Error while adding devices' />)
    yield all([
      put(DevicesActions.addDevicesComplete())
    ])
  }
}

function deviceDataFormat(devices)
{
  return devices.map(device => (
    {
      name:device.alias,
      displayModel:device.model,
      serialNumber:device.serialNumber
    }
  ))
}

export function * getDeviceTypes (api) {
  const response = {
    data: [
      { deviceType: 'Mobile Computer' }
    ]
  }
  yield put(DevicesActions.updateDeviceTypes(getData(response.data)))
}

function getData (data) {
  const response = []
  for (let i = 0; i < data.length; i++) {
    response[i] = {
      value: i,
      text: data[i].deviceType,
      data: data[i]
    }
  }
  return response
}

export function * devicesSagas () {
  yield all([
    takeLatest(DevicesTypes.GET_DEVICES_LIST, getDevicesList, DevicesApi),
    takeLatest(DevicesTypes.DEVICE_DISCOVERY, deviceDiscovery, DevicesApi),
    takeLatest(DevicesTypes.DISCOVERED_DEVICES, discoveredDevices, DevicesApi),
    takeLatest(DevicesTypes.GET_DEVICE_TYPES, getDeviceTypes, DevicesApi),
    takeLatest(DevicesTypes.ADD_DEVICES, addDevices, DevicesApi)
  ])
}
