import React, { useState, useCallback } from 'react'
import { Divider } from '@scuf/common'
import config from 'Config/AppConfig'
import Software from '../Software'
import {
  StyledConfirmationContainer,
  StyledReviewText,
  StyledSoftwareContainer,
  StyledDevicesContainer,
  StyledSubTitle,
  StyledAssignedContainer,
  StyledAssignedIcon,
  StyledAssignedText,
  StyledLabel,
  StyledBundleName,
  StyledDataTable,
  StyledScheduleContainer,
  StyledScheduleSubContainer,
  StyledDiv,
  StyledValue
} from './confirmation.styles'

const columns = 
config.nonSinaps?
[
  { field: 'name', header: 'Alias', render: Render },
  { field: 'displayModel', header: 'Model', render: Render },
  { field: 'serialNumber', header: 'Serial Number', render: Render }
]
:
[
  { field: 'name', header: 'Alias', render: Render },
  { field: 'displayModel', header: 'Model', render: Render },
  { field: 'serialNumber', header: 'Serial Number', render: Render },
  { field: 'status', header: 'State', initialWidth: '10%', render: RenderState }
]

function Render ({ value }) {
  return <p>{value}</p>
}

function RenderState ({ value }) {
  let status
  if (!value) status = 'Unknown'
  else status = value

  switch (status) {
    case 'ACTIVE': return <p>Active</p>
    case 'LOST': return <p>Lost</p>
    case 'OUT_FOR_REPAIR': return <p>Out for repair</p>
    case 'OUT_OF_SERVICE': return <p>Out of service</p>
    default: return <p>Unknown</p>
  }
}

const Confirmation = ({
  licenseInstaller
}) => {
  const [assignedClicked, setAssignedClicked] = useState(false)
  const { selectedSoftwares, bundleName, selectedDevicesList, scheduleTimezone, scheduleFromDatetime, scheduleToDatetime, totalDevicesCount, deviceFilterType  } = licenseInstaller

  const handleAssignClick = useCallback(
    () => {
      setAssignedClicked(prevAssignedClicked => !prevAssignedClicked)
    },
    [setAssignedClicked]
  )

  return (
    <StyledConfirmationContainer>
      <StyledReviewText>
        Review the software licenses you are about to install, in case you miss something you can go back and do the required changes.
      </StyledReviewText>
      <StyledSoftwareContainer>
        <StyledSubTitle>
          Software
        </StyledSubTitle>
        <Software selectedSoftwares={selectedSoftwares} />
      </StyledSoftwareContainer>
      <StyledDevicesContainer>
        <StyledSubTitle>
          Devices
        </StyledSubTitle>
        <StyledAssignedContainer>          
          <StyledAssignedText>
          {`${deviceFilterType == 1 ? totalDevicesCount : selectedDevicesList.length} Assigned Devices`}
          </StyledAssignedText>
        </StyledAssignedContainer>
        {assignedClicked
          ? <><StyledLabel>Name: </StyledLabel>
            <StyledBundleName>{bundleName}</StyledBundleName>
            <StyledDataTable
              data={selectedDevicesList}
              scrollHeight='15vh'
              scrollable={true}
            >
              {columns
                .map(key =>
                  <StyledDataTable.Column
                    key={key.field}
                    field={key.field}
                    initialWidth={key.initialWidth}
                    renderer={key.render}
                    header={key.header}
                    sortable={true}
                  />)}
            </StyledDataTable>
            </>
          : null}
      </StyledDevicesContainer>
      {scheduleTimezone
        ? <StyledScheduleContainer>
          <StyledSubTitle>
            Schedule
          </StyledSubTitle>
          <StyledScheduleSubContainer>
            <StyledDiv>
              <StyledLabel>
                TimeZone
              </StyledLabel>
              <StyledValue>
                {scheduleTimezone}
              </StyledValue>
            </StyledDiv>
            <Divider direction='vertical' />
            <StyledDiv>
              <StyledLabel>
                Installation Date & Time
              </StyledLabel>
              <StyledValue>
                {scheduleFromDatetime} to {scheduleToDatetime}
              </StyledValue>
            </StyledDiv>
          </StyledScheduleSubContainer>
          </StyledScheduleContainer>
        : null}
    </StyledConfirmationContainer>
  )
}

export default Confirmation
