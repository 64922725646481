import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'
import { SuccessToast, ErrorToast } from '../../Themes/ScufStyledComponents'

export const StyledModal = styled(Modal)`
  background: ${props => props.theme.background};
  & .content {
    //overflow: auto !important;
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .scrolling.content {
    overflow: auto !important;
  }
  & .actions {
    padding:1rem !important;
  }
  & .actions .bundleErrMsg{
    width: 100%;
    color:#F44336;
    align-self: center;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
  & .ui.button.primary.disabled{
    cursor: default !important;
  }
  & .steps.ui .link.item{
    height: 80px !important;
  }
  & .ui.steps .step .title{
    font-size: 1rem;
  }
  & .ui.ordered.steps .step:before{
    font-size: 1.75rem;
    margin-right: .5rem !important;
  }
  & .ui.steps .step{
    justify-content: flex-start !important;
  }
 `
export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledContentBody = styled.div`
  display:flex;
  min-height: 50vh;
  max-height: 100vh;
  border: 1px solid #E0E0E0;
  padding: 1rem;
 // overflow: auto;
`

export const StyledSuccessToast = styled(SuccessToast)`
  width:100%  !important;
`

export const StyledErrorToast = styled(ErrorToast)`
  width:100%  !important;
`
export const ToastContainer = styled.div`
  margin-left:40%;
`

export const HeaderDiv = styled.div`
  display: flex !important;
`

export const StyledDiv = styled.div`
  flex-grow: 1  !important;
  flex: 1  !important;
  textAlign: left  !important;
  flexDirection: row  !important;
`
