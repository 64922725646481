import { createStructuredSelector } from 'reselect'

const loading = ({ licenseInstaller }) => licenseInstaller.getIn(['loading'], false)
const selectedSoftwares = ({ licenseInstaller }) => licenseInstaller.getIn(['selectedSoftwares'], [])
const bundleName = ({ licenseInstaller }) => licenseInstaller.getIn(['bundleName'], '')
const selectedDevicesList = ({ licenseInstaller }) => licenseInstaller.getIn(['selectedDevicesList'], '')
const scheduleType = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleType'], 2)
const scheduleTimezone = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleTimezone'], null)
const scheduleFromDate = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleFromDate'], null)
const scheduleToDate = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleToDate'], null)
const scheduleFromTime = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleFromTime'], null)
const scheduleToTime = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleToTime'], null)
const scheduleFromDatetime = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleFromDatetime'], null)
const scheduleToDatetime = ({ licenseInstaller }) => licenseInstaller.getIn(['scheduleToDatetime'], null)
const deviceFilterType = ({ licenseInstaller }) => licenseInstaller.getIn(['deviceFilterType'], '')
const selectedSite = ({ licenseInstaller }) => licenseInstaller.getIn(['selectedSiteId'], '')
const selectedDevicesCount = ({ licenseInstaller }) => licenseInstaller.getIn(['totalDevicesCount'], '')
const siteId = ({ licenseInstaller }) => {
  const softwares = licenseInstaller.getIn(['selectedSoftwares'], [])
  return softwares.length > 0 ? softwares[0].siteId : null
}
const showToast = ({ licenseInstaller }) => licenseInstaller.getIn(['showToast'], 0)

export default createStructuredSelector({
  loading,
  selectedSoftwares,
  bundleName,
  selectedDevicesList,
  scheduleType,
  scheduleTimezone,
  scheduleFromDate,
  scheduleFromTime,
  scheduleToDate,
  scheduleToTime,
  scheduleFromDatetime,
  scheduleToDatetime,
  siteId,
  showToast,
  deviceFilterType,
  selectedSite,
  selectedDevicesCount
})
