import config from 'Config/AppConfig'

const pagination = {
  pageNumber: 1,
  pageSize: 5000
}
export class OpintelLicensesService {
  constructor (api) {
    this.api = api
    if (config.nonSinaps) { this.licenseController = '/LicenseManagementSA' } else { this.licenseController = '/LicenseManagement' }
  }

  getOpintelLicenses = (pageParams = pagination) => this.api.get(this.licenseController + '/subslicenses', pageParams, { sort: [{ property: 'lastImportedOn', ascending: 'true' }] })
}
