import { create } from 'apisauce'

import config from '../Config/AppConfig'

const apiSauceClient = create({
  baseURL: config.api.connectedaidc,
  timeout: 60000,
  headers: config.api.apiKey
    ? {
      apikey: config.api.apiKey
    }
    : undefined
})

export default apiSauceClient

export const setToken = token => {
  const accessToken = getQueryParams('accessToken', window.location.href) || ''
  if (global.sessionStorage) {
    global.sessionStorage.setItem('accessToken', accessToken)
  }
  apiSauceClient.setHeader('Authorization', `Bearer ${accessToken}`)
}

const getQueryParams = (params, url) => {
  const href = url
  const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i')
  const queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}

setToken()
