import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { Title } from '../../../../Themes/ScufStyledComponents'
import { Modal, Button } from '@scuf/common'
import config from 'Config/AppConfig'

const SessionExpiryModal = ({
  sessionExpired,
  reset
}) => {
  const [sessionExpiryModalOpen, setsessionExpiryModalOpen] = useState(false)

  function handleOkModal () {
    window.location.assign(config.baseUrl)
  }

  function handleCloseModal () {
    setsessionExpiryModalOpen(false)
    reset()
  }

  return (
    <Modal size='mini' closeIcon={true} onClose={handleCloseModal} open={sessionExpiryModalOpen || sessionExpired}>
      <Modal.Header>
        <Title style={{ textTransform: 'none' }}>Session Expired</Title>
      </Modal.Header>
      <Modal.Content>
        <p>Your current session has expired. Please login again.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button fluid={true} type='secondary' size='small' content='OK' onClick={handleOkModal} />
        <Button fluid={true} type='secondary' size='small' content='Close' onClick={handleCloseModal} />
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(SessionExpiryModal)
