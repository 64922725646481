import { ApisauceInstance } from 'apisauce'
import { TImportOnPremPayload } from '../types'
import config from 'Config/AppConfig'

export class LicenseImportOnPremService {
  api:ApisauceInstance
  licenseController: any
  constructor (api: ApisauceInstance) {
    this.api = api
    if (config.nonSinaps) 
      this.licenseController ='/LicenseManagementSA'
    else
      this.licenseController ='/LicenseManagement'
  }

  importLicense = (params: TImportOnPremPayload) => this.api.post(this.licenseController + '/licensefile', params)
}
