import React from 'react'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'

import { OthersApi } from 'Services'
import { SessionExpiryActions } from 'Features/SessionExpiry/store'
import { OthersTypes, OthersActions } from './actions'
import { ErrorToast, SuccessToast } from 'Themes/ScufStyledComponents'

export function * getOthersList (api) {
  try {
    const response = yield call(api.getOthersList)
    if (response.ok) {
      yield put(OthersActions.updateOthers(formatOnpremisesData(response.data.licenseBundles)))
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield all([
        put(OthersActions.updateOthersListFailure())
      ])
    }
  } catch (_) {
    yield all([
      put(OthersActions.updateOthersListFailure())
    ])
  }
}

function formatOnpremisesData (data) {
  const response = []
  let count = 0

  data.forEach((item) => {
    item.deviceLicensesList.forEach((licenseItem) => {
      response[count++] = {
        bundleName: item.bundleName,
        id: item.id,
        siteId: item.siteId,
        compatibleDeviceType: item.compatibleDeviceType,
        featureName: licenseItem.featureName,
        activationId: licenseItem.activationId,
        maintenanceExpiration: licenseItem.maintenanceExpiration,
        expirationDate: licenseItem.expirationDate,
        quantity: licenseItem.quantity,
        createdOn: item.createdOn,
        createdBy: item.createdBy
      }
    })
  })
  return response
}

export function * othersBundleExport (api, { licenseBundle }) {
  try {
    const response = yield call(api.othersBundleExport, licenseBundle)
    if (response.ok) {
      const { statusCode } = response.data.licenseResponseBundle
      yield put(OthersActions.updateExportInProgress())
      if (statusCode === '200') {
        // On Success, after file downloading unselect the row.
        const fileContent = atob(response.data.downloadXml.xmlContent)
        const blob = new Blob([fileContent], { type: 'text/xml' })
        if (blob.size > 0) {
          saveAs(blob, response.data.downloadXml.xmlName+'.xml', true)
          toast(<SuccessToast message='License Bundle Exported Successfully.' />)
        }
      } else if (statusCode === '107') {
        toast(<ErrorToast message='License Bundle Not Found.' />)
      } else {
        toast(<ErrorToast message='Failed to Export License Bundle' />)
      }
    } else {
      if (response.status === 401) {
        yield all([
          put(SessionExpiryActions.sessionExpiry())
        ])
      }
      yield put(OthersActions.updateExportInProgress())
      toast(<ErrorToast message='Failed to export License Bundle' />)
    }
  } catch (_) {
    yield put(OthersActions.updateExportInProgress())
    toast(<ErrorToast message='Failed to export License Bundle' />)
  }
}

export function * othersSagas () {
  yield all([
    takeLatest(OthersTypes.GET_OTHERS_LIST, getOthersList, OthersApi),
    takeLatest(OthersTypes.OTHERS_BUNDLE_EXPORT, othersBundleExport, OthersApi)
  ])
}
