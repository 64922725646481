import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { LicensesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  licensesList: [],
  licenseBundlesList: [],
  exportInProgress: false,
  currentUserEmail: '',
  currentUserAccessibleDeviceList: []
})

const loading = state => state.merge({ loading: true })
const updateLicensesList = (state, { licenses }) => state.merge({ licensesList: licenses, loading: false })
const updateLicensesFailure = state => state.merge({ licensesList: [], loading: false })
const updateLicenseBundlesList = (state, { licenseBundles }) => state.merge({ licenseBundlesList: licenseBundles, loading: false })
const updateLicenseBundleFailure = state => state.merge({ licenseBundlesList: [], loading: false })
const updateLicenseBundleExportFailure = state => state.merge({ loading: false })
// const licenseBundleExport = (state, { licenseBundle }) => state.merge({ Licensebundle: licenseBundle })
const updateExportInProgress = state => state.merge({ exportInProgress: !state.exportInProgress })
const updateLoginEmailAddress = (state, { loginEmailAddress }) => state.merge({ currentUserEmail: loginEmailAddress, loading: false })
const updateUserAccessibleDeviceList = (state, { devices }) => state.merge({ currentUserAccessibleDeviceList: devices, loading: false })
const reset = () => INITIAL_STATE

export const licensesReducer = createReducer(INITIAL_STATE, {
  [LicensesTypes.GET_LICENSES]: loading,
  [LicensesTypes.GET_LICENSE_BUNDLES]: loading,
  [LicensesTypes.GET_USER_DETAILS]: loading,
  [LicensesTypes.GET_DEVICES]: loading,
  [LicensesTypes.UPDATE_LICENSES]: updateLicensesList,
  [LicensesTypes.UPDATE_LICENSES_FAILURE]: updateLicensesFailure,
  [LicensesTypes.UPDATE_LICENSE_BUNDLES]: updateLicenseBundlesList,
  [LicensesTypes.UPDATE_LICENSE_BUNDLE_FAILURE]: updateLicenseBundleFailure,
  [LicensesTypes.UPDATE_LICENSE_BUNDLE_EXPORT_FAILURE]: updateLicenseBundleExportFailure,
  [LicensesTypes.UPDATE_EXPORT_IN_PROGRESS]: updateExportInProgress,
  [LicensesTypes.UPDATE_LOGIN_EMAIL_ADDRESS]: updateLoginEmailAddress,
  [LicensesTypes.UPDATE_USER_ACCESSIBLE_DEVICE_LIST]: updateUserAccessibleDeviceList,
  [LicensesTypes.RESET]: reset
})
