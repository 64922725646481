import styled from 'styled-components/macro'
import { Modal, Button } from '@scuf/common'

export const StyledModal = styled(Modal)`
`

export const StyledDiv = styled.div`
    flex-grow: 1  !important;
    flex: 1  !important;
    textAlign: left  !important;
    flexDirection: row  !important;
`
export const StyledBodyDesc = styled.div`
    font-family: "Honeywell Sans Web",Arial,Helvetica,sans-serif;
    color: #707070;
    font-weight: 500;
    font-size: .875rem;
`

export const StyledFooter = styled.div`
    display: flex;
    width: 100%; 

    Button{
        width: 100%
    }
`
