import { createStructuredSelector, createSelector } from 'reselect'
import { isEmpty } from 'ramda'

export const alerts = ({ notifications }) => {
  if (isEmpty(notifications.notificationsList)) {
    return []
  }
  return notifications.getIn(['notificationsList'], [])
}

export const unreadAlerts = createSelector([alerts], _alerts => {
  if (isEmpty(_alerts)) {
    return []
  }
  return _alerts
    .filter(alert => alert.status === 'unread')
    .map(alert => {
      return { ...alert }
    })
})

export default createStructuredSelector({
  unreadAlerts,
  alerts
})
