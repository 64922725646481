import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Button } from '@scuf/common'
import { Title } from 'Themes/ScufStyledComponents'
import { linkStateToProps, linkDispatchToProps } from '../../types'
import Loader from 'Components/Loader'
import {
  StyledModal,
  StyledModalContent,
  StyledInput,
  StyledDropdown
} from './licenseImportOnPrem.styles'

export type TOwnProps = {
  importLicenseOnPremModalOpen: boolean,
  handleCloseModal: () => void
}

type TProps = TOwnProps & linkStateToProps & linkDispatchToProps

const LicenseImportOnPremModal:React.FC<TProps> = ({
  importOnPremLicense,
  handleCloseModal,
  loading,
  reset,
  refreshDevices,
  importLicenseOnPremModalOpen
}) => {
  useEffect(() => {
    return () => reset()
  }, [reset])

  const [activationId, setActivationId] = useState<string>('')
  const [hostId, setHostId] = useState<string>('')
  const [devicesCount, setDevicesCount] = useState<number>(0)
  const [downloadType, updateDownloadType] = useState<number>(1)
  const downloadTypes = getDownloadTypes()
  
  function handleImportOnPremLicense () {
    importOnPremLicense(activationId, hostId, downloadType, devicesCount)
  }

  function handleDownloadTypeChange (event: React.ReactText) {
    updateDownloadType(parseInt(event.toString()))
  }

  const handleDeviceCountChange = useCallback(
    (val) => {
      if (val === '') {
        setDevicesCount(0)
        return
      }
      const deviceCount = parseInt(val)
      if (deviceCount > 0 && deviceCount <= 99999) {
        setDevicesCount(val)
      }
    }, [setDevicesCount])

  const handleKeyDown = (event: { keyCode: number; preventDefault: () => void }) => {
    if (event.keyCode === 69) {
      event.preventDefault()
    }
  }

  const handleHostIdChange = useCallback(
    (val) => {
      const hostid = val.toString()
      const validHostId = hostid.replace(/[^0-9a-zA-Z \s+-.@_]+/g, '')
      setHostId(validHostId.trim())
    }, [setHostId])

  function getDownloadTypes () {
    const types = [
      { downloadtype: 'Download Now' },
      { downloadtype: 'Send as Email' },
      { downloadtype: 'Send Email and Download' }
    ]
    const response: { value: number; text: string; data: { downloadtype: string } }[] = []
    types.forEach((item, i) => {
      response[i] = {
        value: i,
        text: item.downloadtype,
        data: item
      }
    })
    return response
  }

  function handleClose () {
    setActivationId('')
    setHostId('')
    setDevicesCount(0)
    handleCloseModal()
    reset()
    refreshDevices()
  }

  return (

    <StyledModal size='mini' closeIcon={true} onClose={handleClose} open={importLicenseOnPremModalOpen} closeOnDimmerClick={false}>
      <Modal.Header>
        {loading ? <Loader loading={loading} /> : <div />}
        <Title style={{ textTransform: 'none' }}>Import HCC Licenses</Title>
      </Modal.Header>
      <StyledModalContent>
        <p>Enter the activation ID, the number of devices and the host id of your software</p>

        <StyledInput
          fluid={true}
          placeholder='Activation ID'
          label='Activation ID'
          value={activationId}
          onChange={(val:string) => setActivationId(val.replace(/\s/g, ''))}
          indicator='required'
          maxLength={255}
        />

        <StyledInput
          fluid={true}
          placeholder='Host ID'
          label='Host ID'
          value={hostId}
          onChange={(val: string) => handleHostIdChange(val)}
          indicator='required'
        />

        <StyledInput
          indicator='required'
          fluid={true}
          placeholder='Devices'
          type='number'
          label='Number of Devices'
          value={devicesCount > 0 && devicesCount <= 99999 ? devicesCount : ''}
          max='99999'
          onKeyDown={handleKeyDown}
          onChange={(val: number) => handleDeviceCountChange(val)}
          helperText='Count requested should be less than or equal to the purchased count'
        />
        <StyledDropdown
          label='Download Type'
          fluid={true}
          placeholder='Download Type'
          defaultValue={1}
          options={downloadTypes}
          onChange={handleDownloadTypeChange}
        />

      </StyledModalContent>
      <Modal.Footer>
        <Button fluid={true} type='secondary' size='small' content='Cancel' onClick={handleClose} />
        <Button
          fluid={true} type='primary' size='small' content='Import'
          disabled={!(activationId.length > 0 && hostId.length > 0 && devicesCount > 0 && !loading)}
          onClick={handleImportOnPremLicense}
        />
      </Modal.Footer>
    </StyledModal>
  )
}

export default LicenseImportOnPremModal
