import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { SessionExpiryActions } from '../../store'
import SessionExpiryModal from './sessionExpiryModal'
import mapStateToProps from './sessionExpiryModal.selector'

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(SessionExpiryActions.reset()),
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(SessionExpiryModal)
