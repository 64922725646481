import React, { MouseEvent, useState } from 'react'

import LicenseImportOnPremModal from './Modal'
import {
  ActionBarSection,
  ActionContainer,
  ActionText,
  StyledIcon
} from './licenseImportOnprem.styles'

const LicenseImportOnPrem:React.FC = () => {
  const [importLicenseOnPremModalOpen, setImportLicenseOnPremModalOpen] = useState<boolean>(false)

  function handleOpenModal (e: MouseEvent) {
    e.preventDefault()
    setImportLicenseOnPremModalOpen(true)
  }

  function handleClosenModal () {
    setImportLicenseOnPremModalOpen(false)
  }

  return (
    <>
      <ActionBarSection style={{ justifyContent: 'flex-end' }}>
        <ActionContainer
          onClick={handleOpenModal}
        >
          <StyledIcon
            name='cloud-download'
            root='common'
            size='xlarge'
          />
          <ActionText>
            Import HCC Licenses
          </ActionText>
        </ActionContainer>
      </ActionBarSection>

      <LicenseImportOnPremModal
        handleCloseModal={handleClosenModal}
        importLicenseOnPremModalOpen={importLicenseOnPremModalOpen}
      />
    </>
  )
}

export default LicenseImportOnPrem
