import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'
import { SchedulesTypes } from './actions'

export const INITIAL_STATE = Immutable({
  loading: false,
  schedulesList: []
})

const loading = state => state.merge({ loading: true })
const requestComplete = state => state.merge({ loading: false })
const updateSchedulesList = (state, { schedules }) => state.merge({ schedulesList: schedules, loading: false })
const updateSchedulesFailure = state => state.merge({ schedulesList: [], loading: false })

export const schedulesReducer = createReducer(INITIAL_STATE, {
  [SchedulesTypes.GET_SCHEDULES]: loading,
  [SchedulesTypes.LOADING]: loading,
  [SchedulesTypes.UPDATE_SCHEDULES]: updateSchedulesList,
  [SchedulesTypes.UPDATE_SCHEDULES_FAILURE]: updateSchedulesFailure,
  [SchedulesTypes.HANDLE_DELETE]: loading,
  [SchedulesTypes.REQUEST_COMPLETE]: requestComplete
})
