import { pipe } from 'ramda'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { LicenseImportOnPremActions } from '../../store'
import LicenseImportOnPremModal from './licenseImportOnPremModal'
import mapStateToProps from './licenseImportOnPrem.selector'
import { linkDispatchToProps } from '../../types'
import { OthersActions } from '../../../Others/store'

const mapDispatchToProps = (dispatch: Dispatch): linkDispatchToProps => ({
  importOnPremLicense: (activationId, hostId, downloadType, devicesCount) => dispatch(LicenseImportOnPremActions.importLicense(activationId, hostId, downloadType, devicesCount)),
  reset: () => dispatch(LicenseImportOnPremActions.reset()),
  refreshDevices: () => dispatch(OthersActions.getOthersList())
})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(LicenseImportOnPremModal)
