import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

const NotificationsWrapper = styled.div`
position: absolute;
top: 37px;
right: 0;
background-color: ${({ theme }) => theme.black ? '#303030' : '#FFFFFF'};
box-shadow: 2px 2px 8px rgba(0,0,0,.2);
`
const NotificationLine = styled.div`
display: flex;
flex: 1;
flex-direction:row;
width: 31.25rem;
max-width: 31.25rem;
align-items: center;
border-bottom: 1px solid #c0c0c0;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
padding-left: 1rem;
`
const NotificationItem = styled.div`
display: flex;
flex: 1;
flex-direction:row;
align-items: center;
border-bottom: 1px solid #c0c0c0;
padding-top: 0.5rem;
padding-bottom: 1.5rem;
padding-left: 1rem;
`
const NotificationText = styled.div`
overflow: hidden;
white-space: nowrap;
width: 31.25rem;
text-overflow: ellipsis;
padding-right: 1rem;
`
const NotificationTextStyle = styled.div`
overflow: hidden;
white-space: pre;
text-overflow: ellipsis;
padding-right: 1rem;
`

const HeaderNotificationsWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0.75rem 1.5rem !important;
  }
`

const BellWrapper = styled.div`
position: relative;
background-color: ${({ background }) => background || ''};
color: ${({ color }) => color || ''};
z-index: 100;
cursor: pointer;
margin-right: 1rem;
@media (max-width: 768px) {
  border-bottom: none;
}
& .ui.label.badge.red{
  @media (max-width: 768px) {
    margin-top: -10px;
  }
}
`
const BellBadge = styled.div`
position: absolute;
top: -5px;
left: -10px;
`
const NotificationHeader = styled.div`
display: flex;
flex: 1;
flex-direction: row;
justify-content: space-between;
font-weight: bold;
`
const NotificationRow = styled.div`
display: flex;
flex: 1;
flex-direction: row;
`
const NotificationColumn = styled.div`
display: flex;
flex: 1;
flex-direction: column;
max-width: 450px;
padding: 0 1rem;
`
const NotificationCol = styled.div`
display: flex;
flex: 1;
flex-direction: column;
padding: 0 1rem;
`

const NotificationHeaderText = styled.div`
`

const NotificationHeaderTitle = styled.div`
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
width: 70%;
`

const ZeroNotifications = styled(NotificationLine)`

`
const Alerts = styled.div`
font-family: "Honeywell Sans Web";
font-weight: 500;
margin-left: -7px;
`
const StyledBadge = styled.div`
background-color: ${({ color }) => color === 'red' ? '#be271c' : color};
font-size: .65rem;
font-weight: 600;
line-height: .65rem;
color: #ffffff;
padding: .2rem .25rem;
border-radius: .5rem;
min-width:17px;
display:flex;
justify-content: center;
align-items: center;

`
const StyledModal = styled(Modal)`
  width: 90% !important;
  max-width: 100%;
  overflow: auto;
  background: ${props => props.theme.background};
  & .content {
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
`
const NotificationsFooter = styled.div`
display: flex;
flex: 1;
flex-direction:row;
justify-content: flex-end;
padding: 0.5rem 1rem;
font-weight: bold;
`
const ActionBarSection = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'} ;
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
`
const StyledModalContent = styled(Modal.Content)`
  overflow: visible !important;
`
const SiteName = styled.div`
font-size: 0.75rem;
`

const StyledP = styled.p`
font-size: 0.875rem;
word-wrap: break-word;
@media (max-width: 577px) {
width: 10rem;
white-space: ${props => props.ellipsis ? 'normal' : 'nowrap'}; 
overflow: hidden;
text-overflow: ellipsis; 
}
`
const CardWrap = styled.div`
height:75px;
width:100%;
display: flex;
flex-wrap: nowrap;
flex-direction: row;
@media (max-width: 768px) {
  height: fit-content;
}
`

const IconWrap = styled.div`
width:60px;
height:100%;
padding: 17px 10px;
.h-icon.scuf-large{
  font-size: 2.5rem !important;
}
 @media (max-width: 400px) {
  width: 35px;
  padding: 17px 0px 17px 5px;
}

`
const ContentWrap = styled.div`
height:100%;
display: flex;
flex:1;
flex-direction: column;
`

const ContentTitle = styled.div`
width:100%;
height:50%;
padding:12px;
`
const ContentText = styled.div`
padding:0 12px 5px 12px;
width:100%;
//height:50%;
color:#606060;
font-size:1rem;
display: flex;
flex: 1;
justify-content: space-between;
`
const StyledH5 = styled.h5`
float:left;
font-weight: ${({ status }) => status === 'unread' ? '700' : ''};
font-size:.875rem;
@media (max-width: 768px) {
  margin: 0rem;
}
`
const Timestamp = styled.div`
float:right;
font-weight: 700;
font-size: 0.875rem;
`

const AlertsTitle = styled.h1`
  font-size:1.6rem;
  font-weight:500;
  text-align: left;
  margin-left:1rem;
  @media (max-width: 768px) {
    margin-top: 1.5rem !important;
  }

`
export {
  AlertsTitle,
  Timestamp,
  StyledH5,
  IconWrap,
  ContentWrap,
  ContentTitle,
  ContentText,
  CardWrap,
  SiteName,
  StyledP,
  NotificationsWrapper,
  NotificationLine,
  NotificationText,
  HeaderNotificationsWrapper,
  BellWrapper,
  BellBadge,
  NotificationHeader,
  NotificationColumn,
  NotificationRow,
  NotificationHeaderText,
  NotificationHeaderTitle,
  ZeroNotifications,
  Alerts,
  StyledBadge,
  StyledModal,
  NotificationsFooter,
  ActionBarSection,
  ActionContainer,
  StyledModalContent,
  NotificationItem,
  NotificationCol,
  NotificationTextStyle
}
