import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  loading: null,
  getOpintelLicenses: null,
  updateOpintelLicenses: ['opintelLicenses'],
  updateOpintelLicensesFailure: null
}, {
  prefix: '@OPINTELLICENSES/'
})

export const OpintelLicensesTypes = Types
export const OpintelLicensesActions = Creators
