import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Others from './others'
import mapStateToProps from './others.selector'
import { OthersActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getOthersList: () => dispatch(OthersActions.getOthersList()),
  othersBundleExport: (licenseBundle) => dispatch(OthersActions.othersBundleExport(licenseBundle)),
  updateExportInProgress: () => dispatch(OthersActions.updateExportInProgress())
})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Others)
