import { createStructuredSelector } from 'reselect'

const licenseBundles = ({ licenses }) => licenses.getIn(['licenseBundlesList'], [])
const loading = ({ licenses }) => licenses.getIn(['loading'], false)
const exportInProgress = ({ licenses }) => licenses.getIn(['exportInProgress'], false)
const currentUserEmail = ({ licenses }) => licenses.getIn(['currentUserEmail'], '')
const currentUserAccessibleDeviceList = ({ licenses }) => licenses.getIn(['currentUserAccessibleDeviceList'], '')

export default createStructuredSelector({
  licenseBundles,
  loading,
  exportInProgress,
  currentUserEmail,
  currentUserAccessibleDeviceList
})
