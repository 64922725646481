import React, { useEffect } from 'react'
import { setToken } from '../../Services/ApiSauceInstance'

const getQueryParams = (params, url) => {
  const href = url
  const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i')
  const queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}

export const useAppFrame = () => {
  useEffect(() => {
    const accessToken = getQueryParams('accessToken', window.location.href)
    if (global.sessionStorage) {
      global.sessionStorage.setItem('accessToken', accessToken)
      setToken(global.sessionStorage.getItem('accessToken'))
    }

    return () => {
      global.sessionStorage.setItem('accessToken', '')
    }
  }, [])
}
