import { connect } from 'react-redux'
import mapStateToProps from './notification-bell.select'
import NotificationBell from './notification-bell'
import { NotificationsActions } from './store'
import { pipe } from 'ramda'

export const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(NotificationsActions.getNotifications()),
  pollingAction: () => dispatch(NotificationsActions.getNotifications())

})

export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationBell)
