import React from 'react'
import { Tab } from '@scuf/common'
import Licenses from 'Features/Licenses/components/Licenses'
import LicenseBundles from 'Features/Licenses/components/LicenseBundles'
import Schedules from 'Features/Schedules/components/Schedules'
import Historys from 'Features/Historys/components/Historys'
import Devices from 'Features/Devices/components/Devices'
import Others from 'Features/Others/components/Others'
import config from 'Config/AppConfig'
import OpintelLicenses from 'Features/OpintelLicenses/components/OpintelLicenses'
import {
  LICENSES_TAB,
  LICENSES_BUNDLE_TAB,
  SCHEDULE_TAB,
  HISTORY_TAB,
  OTHERS_TAB,
  DEVICES_TAB,
  OPINTEL_LICENSES_TAB
} from 'Utils/constants'

function getActiveTab (index) {
  if (config.nonSinaps) {
    switch (index) {
      case 0:
        return LICENSES_TAB
      case 1:
        return LICENSES_BUNDLE_TAB
      // case 2:
      //   return DEVICES_TAB
      case 2:
        return OTHERS_TAB
      default:
        return LICENSES_TAB
    }
  } else {
    switch (index) {
      case 0:
        return LICENSES_TAB
      case 1:
        return LICENSES_BUNDLE_TAB
      case 2:
        return OTHERS_TAB
      case 3:
        return OPINTEL_LICENSES_TAB
      default:
        return LICENSES_TAB
    }
  }
}

function renderTabList (oiLicenseManagement) {
  const sinapsTabList = [
    { title: 'Licenses', component: <Licenses /> },
    { title: 'License Bundles', component: <LicenseBundles /> },
    { title: 'HCC Licenses', component: <Others /> }
  ]
  if (oiLicenseManagement && !config.nonSinaps) {
    sinapsTabList.push(
      { title: 'OI Subscription', component: <OpintelLicenses /> })
  }
  const nonSinapsTabList = [
    { title: 'Licenses', component: <Licenses /> },
    { title: 'License Bundles', component: <LicenseBundles /> },
    //{ title: 'Devices', component: <Devices /> },
    { title: 'HCC Licenses', component: <Others /> }
  ]

  if (config.nonSinaps) {
    return nonSinapsTabList
  } else {
    return sinapsTabList
  }
}

const TabContainer = ({
  onTabChange,
  activeTabIndex,
  onTabIndexChange,
  flags
}) => {
  const { oiLicenseManagement } = flags
  const handleTabChange = (index) => {
    const activeTab = getActiveTab(index)
    onTabIndexChange(index)
    onTabChange(activeTab)
  }
  return (
    <Tab onTabChange={handleTabChange} activeIndex={activeTabIndex}>
      {
        renderTabList(oiLicenseManagement).map((item) =>
          <Tab.Pane title={item.title} key={item.title}>
            {item.component}
          </Tab.Pane>
        )
      }
    </Tab>
  )
}

export default TabContainer
