import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  reset: null,
  loading: null,
  getOthersList: null,
  updateOthers: ['others'],
  updateOthersListFailure: null,
  othersBundleExport: ['licenseBundle'],
  updateLicenseBundleExportFailure: null,
  updateExportInProgress: null
}, {
  prefix: '@OTHERS/'
})

export const OthersTypes = Types
export const OthersActions = Creators
