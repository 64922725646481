import React, { useEffect, useState } from 'react'
import { ConnectedThemeProvider } from 'Themes/connected-theme-provider'
import { MainTitle, Subtitle } from 'Themes/ScufStyledComponents'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pipe } from 'ramda'
import TabContainer from '../TabContainer'
import { StyledToastContainer } from '../Toasts'
import 'react-toastify/dist/ReactToastify.css'
import { useAppFrame } from './use-app-frame.hooks'
import LicenseImportModal from 'Features/LicenseImport'
import LicenseImportOnPrem from 'Features/LicenseImportOnPrem'
import SessionExpiryModal from 'Features/SessionExpiry'
import AddDevices from 'Features/Devices/components/AddDevices'
import NotificationBell from 'Features/NotificationBell'
import config from 'Config/AppConfig'
import {
  AppFrameContainer,
  BodyWrapper,
  StyledHeader,
  StyledFooter,
  ContentWrapper,
  ActionBarContainer,
  ActionBarSection
} from './app-frame.styles'
import {
  isMobile,
  LICENSES_TAB,
  DEVICES_TAB,
  OTHERS_TAB,
  OPINTEL_LICENSES_TAB

} from 'Utils/constants'

const BetaTitle = (
  <React.Fragment>
    <label> Op. Intel </label>
    {/* <Label>(ßeta) </Label> */}
  </React.Fragment>
)

function renderOnTabChange (activeTab, tabCallback) {
  switch (activeTab) {
    case LICENSES_TAB: return <LicenseImportModal handleTabSwitch={tabCallback} isOpintel={false} />
    case OPINTEL_LICENSES_TAB: return <LicenseImportModal handleTabSwitch={tabCallback} isOpintel={true}/>
    case DEVICES_TAB: return <AddDevices />
    case OTHERS_TAB: return <LicenseImportOnPrem />
    default: return null
  }
}

const AppFrame = (props) => {

  const { oiLicenseManagement } = props?.flags

  const [activeTab, setActiveTab] = useState(config.nonSinaps ? LICENSES_TAB : OPINTEL_LICENSES_TAB)
  const [defaultActiveTabIndex, setDefaultActiveTabIndex] = useState(config.nonSinaps ? 0 : 3)
  const handleTabChange = (activeTabName) => {
    setActiveTab(activeTabName)
  }

  useEffect(() => {
    if (oiLicenseManagement && !config.nonSinaps) {
      setDefaultActiveTabIndex(3)
    } else {
      setDefaultActiveTabIndex(0)
    }
  }, [oiLicenseManagement])

  function handleTabSwitchCallBack (statusCode) {
    if (!config.nonSinaps) {
      if (statusCode === '200') {
        handleTabChange(LICENSES_TAB)
        setDefaultActiveTabIndex(0)
      }else if (statusCode === '204' && oiLicenseManagement) {
        handleTabChange(OPINTEL_LICENSES_TAB)
        setDefaultActiveTabIndex(3)
      }
    }
  }

  function handleTabChangeIndex (index) {
    setDefaultActiveTabIndex(index)
  }

  useAppFrame()
  return (
    <AppFrameContainer>
      <ConnectedThemeProvider>
          <StyledToastContainer
            position='top-right'
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick={false}
            draggable={false}
            rtl={false}
            enableMultiContainer={true}
            toastClassName='toast-notification-wrap'
          />
          <BodyWrapper>
            <StyledHeader
              title={isMobile() ? BetaTitle : 'Operational Intelligence'} menu={false}
            >
              <NotificationBell />
            </StyledHeader>
            <ActionBarContainer>
              <ActionBarSection flexDirection='column'>
                <MainTitle marginBottom='0'>License Manager</MainTitle>
                <Subtitle>Import and select the licenses to be installed on devices.</Subtitle>
              </ActionBarSection>
              {renderOnTabChange(activeTab, handleTabSwitchCallBack)}
              <SessionExpiryModal />
            </ActionBarContainer>
            <ContentWrapper>
            <TabContainer onTabChange={handleTabChange} activeTabIndex={defaultActiveTabIndex} onTabIndexChange={handleTabChangeIndex} {...props} />
            </ContentWrapper>
            <StyledFooter copyrightText='Copyright &#169; 2020 Honeywell International Inc' />
          </BodyWrapper>
          </ConnectedThemeProvider>
    </AppFrameContainer>
  )
}

export default pipe(withLDConsumer())(AppFrame)
