import styled from 'styled-components/macro'
import { Modal, Select, Input } from '@scuf/common'
/* import 'styled-components'
import { MyTheme } from 'Themes/themes'
 */
/* declare module 'styled-components' {
  export interface DefaultTheme extends MyTheme {}
} */

export const StyledModal = styled(Modal)`
  max-height: none !important;
  background: ${props => props.theme.background};
  & .content {
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
`

export const StyledModalContent = styled(Modal.Content)`
  overflow: visible !important;
  max-height: none !important;
`

export const StyledInput = styled(Input)`
  margin-top: 0.75rem;
`

export const StyledSelect = styled(Select)`
  width: auto !important;
  max-width: 100%;
  position: relative !important;
  margin-top: 0.75rem;
`
export const StyledDropdown = styled(Select)`
  width: auto !important;
  position: relative !important;
  margin-top: 0.75rem;

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 512px) {
    width: 100%
  }
`
