import React, { memo, useState, useEffect } from 'react'
import { getAlertColor } from '../../notification-bell.helper'
import { Modal, Icon } from '@scuf/common'
import moment from 'moment'
import { isEmpty } from 'ramda'
import {
  NotificationItem,
  NotificationRow,
  StyledModal,
  NotificationsFooter,
  ActionBarSection,
  ActionContainer,
  StyledModalContent,
  SiteName,
  StyledP,
  CardWrap,
  IconWrap,
  ContentWrap,
  ContentTitle,
  ContentText,
  StyledH5,
  Timestamp,
  AlertsTitle
} from '../../notification-bell.styles'

const NotificationLicensesModal = React.forwardRef(({
  handleCloseModal,
  notificationLicenseModalOpen,
  alerts
}, modalRef) => {
  return (
    <div ref={notificationLicenseModalOpen ? modalRef : null}>
      <StyledModal size='large' closeIcon={true} onClose={handleCloseModal} open={notificationLicenseModalOpen}>
        <Modal.Header>
          <AlertsTitle>Alerts</AlertsTitle>
        </Modal.Header>
        <StyledModalContent>
          {alerts
            .map(alert => (
              <NotificationAlert alert={alert} key={alert.id} />
            ))}
        </StyledModalContent>
        <Modal.Footer />
      </StyledModal>
    </div>
  )
})

NotificationLicensesModal.displayName = 'NotificationLicensesModal'

const Footer = ({
  alerts,
  getNotifications,
  statusUpdateNotifications,
  unreadAlerts,
  modalRef,
  clearCaptureFilterClick
}) => {
  const [notificationLicenseModalOpen, setNotificationLicenseModalOpen] = useState(false)

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  function handleCloseModal () {
    clearCaptureFilterClick()
    setNotificationLicenseModalOpen(false)
  }

  function handleOpenModal () {
    getNotifications()
    setNotificationLicenseModalOpen(true)
    updateUnreadNotificationStatus()
  }

  function updateUnreadNotificationStatus () {
    const readAlerts = []
    if (!isEmpty(unreadAlerts)) {
      unreadAlerts.forEach(alert => readAlerts.push(alert.id))
      const notificationIds = {
        Notifications: readAlerts,
        Status: 'read'
      }
      statusUpdateNotifications(notificationIds)
    }
  }

  return (
    <NotificationsFooter>
      <ActionBarSection style={{ justifyContent: 'flex-end' }}>
        <NotificationLicensesModal
          handleCloseModal={handleCloseModal}
          notificationLicenseModalOpen={notificationLicenseModalOpen}
          alerts={alerts}
          ref={modalRef}
        />
        <ActionContainer
          onClick={() => handleOpenModal()}
        >
          View All
        </ActionContainer>
      </ActionBarSection>
    </NotificationsFooter>
  )
}

const AlertTime = ({ time }) => <p>{moment.utc(time).local().fromNow()}</p>

const NotificationAlert = memo(({ alert }) => {
  return (
    <NotificationItem>
      <NotificationRow>
        <CardWrap>
          <IconWrap>
            {getAlertColor(alert.type).map((key) =>
              <Icon key={key.color} color={key.color} name={key.icon} size='large' />
            )}
          </IconWrap>
          <ContentWrap>
            <ContentTitle>
              <StyledH5 status={alert.status}>{alert.title}</StyledH5>
              <Timestamp> <AlertTime time={alert.time} /></Timestamp>
            </ContentTitle>
            <ContentText>
              <StyledP>{alert.message}</StyledP>
              <SiteName> {alert.siteName}</SiteName>
            </ContentText>
          </ContentWrap>
        </CardWrap>
      </NotificationRow>
    </NotificationItem>
  )
})

NotificationAlert.displayName = 'NotificationAlert'

export default Footer
