import { pipe } from 'ramda'
import { connect } from 'react-redux'
import Schedules from './schedules'
import mapStateToProps from './schedules.selector'
import { SchedulesActions } from '../../store'

const mapDispatchToProps = dispatch => ({
  getSchedules: () => dispatch(SchedulesActions.getSchedules()),
  deleteSelectedCommands: (selectedRows) => dispatch(SchedulesActions.handleDelete(selectedRows))

})
export default pipe(
  connect(mapStateToProps, mapDispatchToProps)
)(Schedules)
